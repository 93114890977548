import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  Input,
  InputGroup,
  Button,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import { authen } from "../../../assets/api/firebase";
import * as actions from "../../../actions/";
import * as yup from "yup";
import hash from "object-hash";
export default function PaymentForm({ basket }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const paymentSchema = yup.object().shape({
    firstname: yup.string().required("กรุณาใส่ข้อมูลให้ถูกต้อง"),
    lastname: yup.string().required("กรุณาใส่ข้อมูลให้ถูกต้อง"),
    address: yup.string().required("กรุณาใส่ข้อมูลให้ถูกต้อง"),
    phoneNumber: yup.string().required("กรุณาใส่เบอร์มือถือ"),
    file: yup.mixed().required(),
    // file: yup.mixed()
    // .test('fileType', "Unsupported File Format", value => ([ "image/jpg",
    //  "image/jpeg",
    //  "image/png"]).includes(value.type))
  });
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      firstname: user.user_name.split(" ").slice(0, -1).join(" "), //เเบ่งชื่อกับนามสกุล
      lastname: user.user_name.split(" ").slice(-1).join(" "),
      address: user.user_address,
      phoneNumber: user.user_tel,
    },
    validationSchema: paymentSchema,
  });

  const onSubmit = (data) => {
    const name = data.file[0].name;
    const date = new Date();
    const pictureName = hash({
      name: name,
      date: date,
    });
    const order_data = basket;
    const uploadTask = authen
      .storage()
      .ref(`images/${pictureName}`)
      .put(data.file[0]);
    uploadTask.on(
      "state_changed",
      (snapshot) => {},
      (error) => {
        console.log(error);
      },
      () => {
        authen
          .storage()
          .ref("images")
          .child(pictureName)
          .getDownloadURL()
          .then((url) => {
            basket.status = "ชำระเงินแล้ว";
            order_data.user_name = data.firstname + " " + data.lastname;
            order_data.user_address = data.address;
            order_data.user_tel = data.phoneNumber;
            order_data.user_email = data.email;
            order_data.status = "ชำระเงินเเล้ว";
            order_data.Silp = url;

            dispatch(actions.basketPost(basket));
            dispatch(actions.basketDelete(basket._id));
          });
      }
    );

    history.push("/");
  };

  return (
    <Container
      style={{
        backgroundColor: "#ffffff",
        boxShadow: "1px 1px 3px #d9d9d9",
        padding: 30,
      }}
    >
      <div
        className="light-th text-danger"
        style={{ minHeight: "70vh", textAlign: "center", fontSize: "40px" }}
      >
        <hr />
        <Row className="mt-4">
          <Col md="7">
            <h4>เเจ้งข้อมูลการชำระเงิน</h4>
          </Col>
          <Col md="5" style={{ fontSize: "12px" }} className="text-right mt-2">
            *กรุณากรอกข้อมูลให้ครบถ้วน{" "}
          </Col>
        </Row>

        <hr />
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="ชื่อภาษาไทย"
              name="firstname"
              innerRef={register}
            />

            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="นามสกุลภาษาไทย"
              name="lastname"
              innerRef={register({
                required: true,
              })}
            />
          </InputGroup>
          <div>
            {" "}
            {(errors.firstname || errors.lastname) && (
              <h5 style={{ fontSize: "15px", margin: "5px" }}>
                {" "}
                กรุณาใส่ข้อมูลให้ถูกต้อง
              </h5>
            )}
          </div>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="textarea"
              placeholder="ที่อยู่"
              name="address"
              innerRef={register}
            />
          </InputGroup>
          <div>
            {" "}
            {errors.address && (
              <p style={{ fontSize: "15px", margin: "5px" }}>
                {errors.address.message}
              </p>
            )}
          </div>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="เบอร์โทรศัพท์โทรศัพท์มือถือ"
              name="phoneNumber"
              innerRef={register}
            />
          </InputGroup>
          <div>
            {" "}
            {errors.phoneNumber && (
              <h5 style={{ fontSize: "15px", margin: "5px" }}>
                {errors.phoneNumber.message}
              </h5>
            )}
          </div>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="file"
              name="file"
              innerRef={register}
            />
          </InputGroup>
          <div>
            {" "}
            {errors.file && (
              <h5 style={{ fontSize: "15px", margin: "5px" }}>
                {errors.file.message}
              </h5>
            )}
          </div>
          <br />
          <Button
            className="mt-2 login-btn"
            style={{ border: "none" }}
            size="lg"
            block
            type="submit"
          >
            <span className="light-th" style={{ fontSize: "15px" }}>
              บันทึกข้อมูล
            </span>
          </Button>
        </form>
      </div>
    </Container>
  );
}

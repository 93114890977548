import React, { useState } from "react";
import {
  Row,
  Container,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  CardFooter,
  CardImg,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

const ArticleList = (props) => {
  const { article, onDelArticle, toggleModalEditArticle } = props;

  const history = useHistory();

  const goToUpdateArticle = (article) => {
    history.push({
      pathname: "/management/article/update_product/" + article.product_id,
      state: { article: article },
    });
  };

  const showArticleList = () => {
    return (
      article && article.map((_article) => {
        return (
          <Col
            xs="6"
            sm="6"
            md="4"
            lg="2"
            className="p-1"
            maxHeight="250px"
            key={_article.product_id}
          >
            <Card style={{ cursor: "pointer" }} className="mb-1">
              
              <CardBody>
                <CardTitle>{_article.product_name}</CardTitle>
               
                <CardSubtitle className="text-left text-muted pt-2">
                  <t>สินค้าคงเหลือ {_article.product_inventory}</t>
                </CardSubtitle>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs="12" sm="6" className="text-center p-1">
                    <Button
                      color="warning"
                      onClick={() => {
                        goToUpdateArticle(_article);
                      }}
                    >
                      เเก้ไข
                    </Button>
                  </Col>
                  <Col xs="12" sm="6" className="text-center p-1">
                    <Button
                      color="danger"
                      onClick={() => {
                        onDelArticle(_article.product_id);
                      }}
                    >
                      ลบ
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        );
      })
    );
  };
  return article ? (
    <>
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <hr />
        {article.length ? (
          <>
            {" "}
            <Container fluid>
              <Row>{showArticleList()}</Row>
            </Container>
          </>
        ) : (
          <>
            <div
              className="light-th text-danger"
              style={{ textAlign: "center", fontSize: "20px" }}
            >
              <br />
              ยังไม่มีรายการสินค้า
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <div
        className="light-th text-danger"
        style={{
          textAlign: "center",
          fontSize: "20px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <br />
        โทเค็นไม่ถูกต้อง
      </div>
    </>
  );
};

export default ArticleList;

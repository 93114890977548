import React from 'react'
import { Container,Row } from 'reactstrap'
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions/"


export default function DisplayConfirm({ basket,history }) {
const dispatch = useDispatch()
    return (
        
        <Container>
            <div style={{
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 3px #d9d9d9",
            }} className="p-3">
                    <h5 className="title text-center text-danger mb-3">ยอดรวม {basket.totalPrice} บาท{" "}</h5>
                    <div className="d-flex justify-content-center">
                        <button className="btn btn-danger title mr-2 mb-3" onClick={() => {dispatch(actions.basketDelete(basket._id))} }>ยกเลิกรายการ</button>
                        <button className="btn btn-success title mr-2 mb-3" onClick={() => history.push("/payment/" + basket.user_id)}>แจ้งชำระเงิน</button>
                    </div>
            </div>
            <br/>
        </Container>
        
    )
}

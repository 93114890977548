import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import * as actions from "../../actions/";
import { Container } from "reactstrap";

import OrderDetail from "./orders/OrderDetail";
import PaymentDetail from "./orders/PaymentDetail";

export default function Status() {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const orders = useSelector((state) => state.orders);

  useEffect(() => {
    if (user == null) {
      history.push("/login");
    } else {
      dispatch(actions.ordersPaidFetch(user));
      console.log("Status | orders | Lenght ", orders.length);
      {
        console.log("Array.isArray(orders)", Array.isArray(orders));
      }
      //console.log("Status | userid ", user.id);
    }
    return () => {};
  }, []);

  const changeStatus = (order, status) => {
    order.status = status;
    // ส่ง email ไปยังผู้ใช้
    axios
      .post(process.env.REACT_APP_API_URL + "/mail", {
        name: "test9",
        email: "chanon.5613@gmail.com",
        message: status,
      })
      .then((res) => {});
    dispatch(actions.ordersPaymentStatusPut(order._id, order, user.id));
  };

  return (
    <Container className="light-th">
      <div className="container col-md-8 text-center">
        <h2>สถานะการสั่งซื้อสินค้า</h2>
      </div>

      {Array.isArray(orders) ? (
        <>
          {orders.map((order) => {
            return (
              <>
                <OrderDetail order={order} />
                <PaymentDetail
                  order={order}
                  user={user}
                  onChangeStatus={changeStatus}
                />
              </>
            );
          })}
        </>
      ) : (
        <>
          <div className="container col-md-8 text-center">
            <br />
            <br />
            <br />
            <h2>ไม่พบรายการชำระเงินของท่าน</h2>
            <h2>
              {" "}
              กลับไป
              <Link className="text-danger" to="/">
                เลือกสินค้า
              </Link>
            </h2>
          </div>
        </>
      )}
    </Container>
  );
}

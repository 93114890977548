import React, { useEffect, useState } from "react";
import {
    Container,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions/"
import ArticleLists from "./article/ArticleListsProduct";
import Categories from "../components/categories/CategoriesProduct"
import * as role from "../util/UserRole"
import { useHistory } from "react-router-dom"

const Home = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const products = useSelector(state => state.products);
    const categories = useSelector(state => state.categories);
    const user = useSelector((state) => state.user);
    const [article, setArticle] = useState([]);
    const [categoryName, setCategoryName] = useState("");

    const allowAccess = user
    ? user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
        ? true
        : false
    : history.push("/login");

    useEffect(() => {
        dispatch(actions.itemsFetch())
        dispatch(actions.categoriesProductFetch())
        setArticle(products)
        return () => {
        }
    },[])

    useEffect(() => {
        setArticle(products)
        return () => {
    
        };
      }, [products]);

    if (allowAccess) {
        return (
            <Container fluid style={{ minHeight: "80vh" }} className="light-th">
                <div className="text-center">
                    <h2 >สถานะสินค้า {categoryName? categoryName:''}</h2>
                </div>
                <div className="text-left"> 
                    <Categories 
                        categories={categories}  
                        article={products}  
                        setArticle={ setArticle}
                        setCategoryName={setCategoryName} 
                    />
                </div>
                <ArticleLists article={article} />
            </Container>
        );
    } else {
        return (
            <Container fluid className="light-th">
                <div className="container col-md-8 text-center">
                <h1 className="text-danger"> ขออภัย </h1>
                <h3>
                    คุณไม่มีสิทธิในการเข้าถึง <br />
                    กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
                </h3>
                </div>
            </Container>
        )
    }
        
};

export default Home;

import {
  PRODUCTS_FETCH,
  PRODUCT_FETCH,
  PRODUCT_CREATE,
  ARTICLE_DELETE,
  PRODUCT_UPDATE,
  ARTICLE_CREATE,
  ARTICLE_UPDATE,
} from "../actions/types";
export default function (state = [], action) {
  switch (action.type) {
    case PRODUCTS_FETCH:
      return action.payload;
    case PRODUCT_FETCH:
      return action.payload;
    case PRODUCT_CREATE:
      return { saved: true, msg: "บันทึกสินค้าเรียบร้อย" };
    case ARTICLE_DELETE:
      return action.payload; //ลบสินค้า
    case ARTICLE_CREATE:
      return action.payload; //เพิ่มสินค้า
    case ARTICLE_UPDATE:
        return action.payload; //{...state, saved: true, msg: "เเก้ไขสินค้าเรียบร้อย" };
    case PRODUCT_UPDATE:
      return { ...state, saved: true, msg: "บันทึกสินค้าเรียบร้อย" };
    default:
      return state;
  }
}

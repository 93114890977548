import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
import * as actions from "../../actions";
import { Table, Container, Row, Col, Button } from "reactstrap";
import TablesStocktakings from "./tables/TablesStocktakingsProduct";
import TablesStockTransactions from "./history/tables/TablesStockTransactions";
import ModalAddStocks from "./modal/ModalAddStocksProduct";
import CategoriesStockProductLists from "../../components/categories/CategoriesStockProductLists";
import * as role from "../../util/UserRole";
import Categories from "../../components/categories/CategoriesProduct"
import ModalEditStock from "./modal/ModalEditStock";
import { useHistory } from "react-router-dom"

import ClipLoader from "react-spinners/ClipLoader";


export default function ManagementStocks() {
  const dispatch = useDispatch();
  const history = useHistory();
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);
  const stocks = useSelector((state) => state.stocks);
  const user = useSelector((state) => state.user);
  const [modalAddStocks, setModalAddStocks] = useState(false); // props สำหรับเปิดปิด modal
  const [modalEditStocks, setModalEditStocks] = useState(false);
  const [article, setArticle] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [category, setCategory] = useState([])
  const [loadingCat, setLoadingCat] = useState(true)

  useEffect(() => {
    dispatch(actions.itemsFetch());
    dispatch(actions.stockProductFetch());
    dispatch(actions.categoriesProductFetch());
    // setArticle(products)
    setCategory(categories)

    return () => {};
  }, []);

  useEffect(() => {
    // setArticle(products)
    setCategory(categories)
    return () => {
    };
  }, 
  [categories]
  );

  console.log(categories);

  

  // method สำหรับ set เปิดหรือปิด modal
  const toggleModalAddStocks = () => setModalAddStocks(!modalAddStocks);
  const toggleModalEditStock = () => setModalEditStocks(!modalEditStocks);

  // อนุญาติให้เข้าถึงได้เฉพาะ ผู้จัดการร้าน และ SUPERUSER
  const allowAccess = user
    ? user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
        ? true
        : false
    : history.push("/login");

  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h2>จัดการสต๊อกสินค้า 
            {/* {categoryName? categoryName:''} */}
            </h2>
        </div>

          <br/>
{/*           
          <div className="sweet-loading text-center">
            <ClipLoader 
              size = {50}
              color = "imfo"
              loading = {loadingCat}
            />
          </div> */}
          <CategoriesStockProductLists category = {category}/>

        {/* <Row>
          <Col>
            
          </Col>
          <Col className="text-right">
            <Categories category = {categories}/>
          </Col>
        </Row> */}

        {/* <hr /> */}
        {/* {Array.isArray(article) ? (
          <>
            <ModalAddStocks
              modal={modalAddStocks}
              toggle={toggleModalAddStocks}
            />
            <ModalEditStock
              modal={modalEditStocks}
              toggle={toggleModalEditStock}
            />
            <div
              style={{
                maxHeight: "100vh",
                overflowY: "auto",
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 3px #d9d9d9",
              }}
            >
              <Table striped>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>ชื่อสินค้า</th>
                    <th>ยอดคงเหลือ</th>
                    <th></th>
                  </tr>
                </thead>

                <tbody>
                  
                  {article.map((_article, index) => {
                    return (
                      <TablesStocktakings
                        key={_article.product_id}
                        article={_article}
                        index={index + 1}
                      />
                    );
                  })}
                </tbody>
              </Table>{" "}
            </div>
            <br />
            <Row>
              <Col className="text-left"></Col>
            </Row>
            <br />
          </>
        ) : (
          <>
            <div className="container col-md-8 text-center">
              <br />
              <br />
              <br />
              <h2>ไม่พบรายการสต๊อกสินค้า</h2>
            </div>
          </>
        )} */}
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
          <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
              คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
          </div>
      </Container>
  )
  }
}

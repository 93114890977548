import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as actions from "../../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";

const ModalEditCategoryName = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { className, modal, toggle, category } = props;
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const { register, handleSubmit, err } = useForm();

  console.log(category);
  const onSubmitNewName = (data) => {
    toggle();
    console.log(data);
    const replacedCategory = {
      category_id: category.category_id,
      category_name: data.category_name,
    };
    dispatch(actions.editCategoryName(replacedCategory));
    dispatch(actions.categoriesFetch());
    history.push("/management/category");
  };

  useEffect(() => {}, []);
  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={unmountOnClose}
      >
        <ModalHeader toggle={toggle}>
          แก้ไขชื่อหมวดหมู่ : {category.category_name}{" "}
        </ModalHeader>
        <ModalBody>
          <from>
            <Label>ใส่ชื่อหมวดหมู่วัตถุดิบใหม่</Label>
            <InputGroup size="lg">
              <Input
                type="text"
                style={{ fontSize: "15px" }}
                placeholder="ชื่อหมวดหมู่ใหม่"
                name="category_name"
                defaultValue={category.category_name}
                innerRef={register}
              />
            </InputGroup>
          </from>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit(onSubmitNewName)}>
            แก้ไข
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalEditCategoryName;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { Container, Button, Row, Col } from "reactstrap";
import Categories from "../../components/categories/CategoriesProduct";
import ArticleLists from "./articleLists/ArticleListsProduct";
import ModalCreateArticleProduct from "./modal/ModalCreateArticleProduct";
import ModalCreateCategory from "./modal/ModalCreateCategory";
import * as role from "../../util/UserRole";
import { Link, useHistory } from "react-router-dom";

export default function ManagementArticle() {
  const dispatch = useDispatch();
  const history = useHistory();
  const products = useSelector((state) => state.products);
  const categories = useSelector((state) => state.categories);
  const user = useSelector((state) => state.user);
  const [article, setArticle] = useState([]);
  const [categoryName, setCategoryName] = useState("");

  // กำหนดสิทะิของหน้าจัดการสินค้าให้สำหรับ Merchant / Admin และ Superuser
  const allowAccess = user
    ? user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
        ? true
        : false
    : history.push("/login");
    
  const [modalCreateArticle, setModalCreateArticle] = useState(false); // props สำหรับเปิดปิด modal

  useEffect(() => {
    dispatch(actions.itemsFetch());
    dispatch(actions.categoriesProductFetch());
    setArticle(products);
    return () => {};
  }, []);

    useEffect(() => {
        setArticle(products);
        return () => { };
      }, [products]);

  const toggleModalCreateArticle = () =>
    setModalCreateArticle(!modalCreateArticle); // method สำหรับ set เปิดหรือปิด modal

  const delArticle = (article_id) => {
    let confim = window.confirm("ยืนยันการลบหรือไม่");
    if (confim) {
      dispatch(actions.articleProductDelete(article_id));
      console.log("ลบสินค้าเเล้ว", article_id);
    } else {
      console.log("ยกเลิกการลบสินค้า");
    }
  };

  
  // Allow this Page show only Administrator
  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <ModalCreateArticleProduct
          modal={modalCreateArticle}
          toggle={toggleModalCreateArticle}
        />

        <div className="  text-center">
          <h2>จัดการสินค้า {categoryName? categoryName:''}</h2>
          <Row>
            <Col className="text-left" sm={3}>
              <Categories 
                  categories={categories}  
                  article={products}  
                  setArticle={ setArticle}
                  setCategoryName={setCategoryName}  
                />
            </Col>

            <Col className="text-right">
              <Button
                className="mr-1"
                color="success"
                onClick={() => {
                  toggleModalCreateArticle(); // เปิด modal สำหรับเพิ่มสินค้า
                }}
              >
                เพิ่มสินค้า
              </Button>{" "}
            </Col>
          </Row>
        </div>
        <ArticleLists article={article} onDelArticle={delArticle} />
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h2>จัดการสินค้า</h2>
          <br />
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
            คุณไม่มีสิทธิในการเข้าถึงระบบจัดการสินค้า <br />
            กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    );
  }
}

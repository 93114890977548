import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import * as role from "../../util/UserRole";
import { Container, Table } from "reactstrap";
import AllUserTables from "./tables/AllUserTables";
import { useHistory } from "react-router-dom"

export default function ManagementUser() {
  const dispatch = useDispatch();
  const history = useHistory();
  const changeStatus = null;
  const user = useSelector((state) => state.user);
  const allUser = useSelector((state) => state.userManagement);

  const allowAccess = user
    ? user.role == role.SUPER_USER_ROLE
        ? true
        : false
    : history.push("/login");


  useEffect(() => {
    dispatch(actions.fetchAllUser());
    return () => {};
  }, []);

  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <h2 className="text-center"> จัดการผู้ใช้งาน </h2>
        <br/>
        <Table>
          <thead>
            <tr>
              <th>#</th>
              <th>ชื่อสมาชิก</th>
              <th>อีเมล</th>
              <th>หมายเลขโทรศัพท์</th>
              <th>สถานะ</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(allUser) ? (
              <>
                {allUser.map((eachUser, index) => {
                  return (
                    <AllUserTables
                      user={eachUser}
                      index={index + 1}
                      onChangeStatus={changeStatus}
                    />
                  );
                })}
              </>
            ) : (
              <>
                <h2>No Data Found</h2>
              </>
            )}
          </tbody>
        </Table>
      </Container>
    );
  } else {
    return (
      <Container fluid className="light-th">
          <div className="container col-md-8 text-center">
          <h1 className="text-danger"> ขออภัย </h1>
          <h3>
              คุณไม่มีสิทธิในการเข้าถึง <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
          </h3>
          </div>
      </Container>
  )
  }
    
}

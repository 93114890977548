import React, { useState, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

const Categories = (props) => {
  const {categories,article,setArticle,setCategoryName} = props;
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  useEffect(() => {
    dispatch(actions.categoriesProductFetch());
    return () => {
    }
  }, [])

  const categoriesLists = () => {
    return (
      
      <DropdownMenu>
        <DropdownItem onClick={() => (
          setArticle(article),
          setCategoryName(article.category_name)
        )}
          // dispatch(actions.itemsFetch())}
          >
          สินค้าทั้งหมด
        </DropdownItem>
        <DropdownItem divider />
        {categories &&
          categories.map((category) => {
            return (
              <DropdownItem
                key={category.category_id}
                onClick={() => getStockFetchFromCategory(category.category_id, category.category_name)}
              >
                {category.category_name}
              </DropdownItem>
            );
          })}
      </DropdownMenu>
    );
  };
  const getStockFetchFromCategory = (id, name) => {
    // dispatch(actions.itemsFetchFromCategory(id));
    const res =article.filter((_article) => _article.category_id == id);
    setCategoryName(name);
    setArticle(res);
  };
  return (
    <Dropdown isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret>หมวดหมู่สินค้า</DropdownToggle>
      <DropdownMenu>{categoriesLists}</DropdownMenu>
    </Dropdown>

    

  );
};

export default Categories;

import React from "react";
import { Container } from "reactstrap";
import { Link, Label } from "react-router-dom"
import moment from "moment"


const Footer = () => {
  return (
    <Container fluid className="light-th">
      <div
        color="light"
        style={{ minHeight: "40px" }}
        className=" shadow-sm p-2  bg-white rounded text-center text-uppercase"
        expand="md"
      >
        <div className="pt-2 text-muted  text-uppercase">
          &copy; {moment().format('YYYY')} BENJAPAO , ศูนย์ส่งเสริมอุตสาหกรรมภาค 10 กรมส่งเสริมอุตสาหกรรม ALL RIGHT RESERVED
        </div>
        
        <div>
          <a href="https://iarc.psu.ac.th/" target="_blank">
              <span className="text-danger">Powered By Intelligent Automation Research Center</span>{" "}
        </a>
          |
          <span className="text-muted">
            {" "}
            Email : iarc.psu@gmail.com
          </span>
        </div>
      </div>
    </Container>
  );
};
export default Footer;

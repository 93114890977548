import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import { Row, Col, Container } from "reactstrap";
import PaymentForm from "./paymentForm/PaymentForm"
import DisplayCart from "../cart/display/DisplayCart"

export default function Payment() {
  const basket = useSelector(state => state.basket)

  return (
    <Container>
      <Row>
        <Col md="5">
            <PaymentForm  basket={basket}/>
        </Col>

        <Col md="7">
            <DisplayCart basket={basket} />
        </Col>
      </Row>
    </Container>
    
  )
}

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Row, Col, Button, Container, Card, CardImg } from "reactstrap";
import Cart from "../cart/Cart";

const ArticleDetail = ({ product, saleman_id, user }) => {
  //const params = useParams();
  useEffect(() => {
    console.log("Article 1 | ", product);
    return () => {};
  }, []);
  return (
    <Container fluid>
      <div
        style={{
          backgroundColor: "#ffffff",
          boxShadow: "1px 1px 3px #d9d9d9",
          padding: "15px",
        }}
        className="col-md-12 col-sm-12 "
      >
        <Row>
          <Col md="6">
            <Card>
              <CardImg
                top
                width="100%"
                src={product.product_image}
                alt="Card image cap"
              />
            </Card>
          </Col>
          <Col md="6">
            <div className="mt-4">
              <h4>{product.product_name}</h4>

              <p style={{ fontSize: "2em", color: "#d93731" }}>
                {" "}
                <b>฿{product.product_price}</b>
              </p>
              <br />
              <Row>
                <Col md="3">
                  <p className="light-th" style={{ fontSize: "13px" }}>
                    รายละเอียด
                  </p>
                </Col>
                <Col md="7">
                  <p style={{ fontSize: "15px" }}>{product.product_detail}</p>
                </Col>
              </Row>
              <Row>
                <Col md="3">
                  <p className="light-th" style={{ fontSize: "13px" }}>
                    มีสินค้าทั้งหมด
                  </p>
                </Col>
                <Col md="7">
                  <p style={{ fontSize: "15px" }}>
                    {product.product_inventory} {product.product_unit}
                  </p>
                </Col>
              </Row>
              <br />
              {/* รถเข็น */}
              {user && <Cart product={product} saleman_id={saleman_id} />}
            </div>
          </Col>
        </Row>
      </div>

      <br />
    </Container>
  );
};

export default ArticleDetail;

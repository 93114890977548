import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import * as actions from "../../actions";
import { Table } from "reactstrap";
import OrderTables from "./tables/OrderTables";
import { Container } from "reactstrap";
import * as TransactionStatus from "../../util/TransactionStatus";

export default function Management() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const orders = useSelector((state) => state.orders);

  const changeStatus = (order, status) => {
    order.status = status;
    // ส่ง email ไปยังผู้ใช้
    // axios
    //   .post(process.env.REACT_APP_API_URL + "/mail", {
    //     name: "test9",
    //     email: "chanon.5613@gmail.com",
    //     message: status,
    //   })
    //   .then((res) => {});
    dispatch(actions.ordersPaymentStatusPut(order._id, order, user));
  };
  useEffect(() => {
    dispatch(actions.ordersPaidFetch(user));
    return () => {};
  }, []);
  return (
    <Container fluid className="light-th">
      <div className="container col-md-8 text-center">
        {/* <h1 style={{ fontSize: 120 }}>404</h1> */}
        <h2>รายการสั่งซื้อสินค้า</h2>
      </div>
      {Array.isArray(orders) ? (
        <>
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>รหัสรายการ</th>
                <th>วันที่สั่งซื้อ</th>
                <th>สินค้า</th>
                <th>ยอดรวม</th>
                <th>ที่อยู่การจัดส่ง</th>
                <th>หมายเลขติดตามสินค้า</th>
                <th>สถานะการดำเนินการ</th>
                <th>ดำเนินการ</th>
              </tr>
            </thead>
            <tbody>
              {orders.map((order, index) => {
                if (order.status != TransactionStatus.PROC_COMPLETE) {
                  return (
                    <OrderTables
                      order={order}
                      index={index + 1}
                      onChangeStatus={changeStatus}
                    />
                  );
                } else {
                  return <></>;
                }
              })}
            </tbody>
          </Table>{" "}
        </>
      ) : (
        <>
          <div className="container col-md-8 text-center">
            <br />
            <br />
            <br />
            <h2>ไม่พบรายการชำระเงิน</h2>
            <h2>
              {" "}
              กลับไป
              <Link className="text-danger" to="/">
                เลือกสินค้า
              </Link>
            </h2>
          </div>
        </>
      )}
    </Container>
  );
}

import { combineReducers } from "redux"
import { reducer as reduxForm } from "redux-form"
import ProductReducer from "./ProductReducer"
import OrderReducer from "./OrderReducer"
import CategoryReducer from "./CategoryReducer"
import UserReducer from "./UserReducer"
import CommentReducer from "./CommentReducer"
import Rating from "./RatingReducer"
import BasketReducer from "./BasketReducer"
import AuthStateReducer from "./AuthStateReducer"
import StockReducer from "./StockReducer"
import UserManagerReducer from "./UserManagerReducer"

const rootRuducer = combineReducers({
  auth: AuthStateReducer,
  orders: OrderReducer,
  products: ProductReducer,
  categories: CategoryReducer,
  user: UserReducer,
  userManagement: UserManagerReducer,
  comments: CommentReducer,
  rating: Rating,
  basket: BasketReducer,
  stocks: StockReducer,
  form: reduxForm,
})
export default rootRuducer

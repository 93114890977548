import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { Link } from "react-router-dom";
import * as actions from "../../../actions/";
import { Table, Container, Row, Col, Button } from "reactstrap";
import TablesStockTransactions from "./tables/TablesStockProductTransactions";
import Categories from "../../../components/categories/CategoriesProduct"
import { useHistory } from "react-router-dom"
import * as role from "../../../util/UserRole";
import SearchStockProductHistory from "../../../components/search/SearchStockHistory"

export default function StockHistory() {
  const dispatch = useDispatch();
  const history = useHistory();
  const stocks = useSelector((state) => state.stocks);
  const categories = useSelector((state) => state.categories);
  const user = useSelector((state) => state.user);
  const [stocksData, setStocksData] = useState([]);

  useEffect(() => {
    dispatch(actions.stockProductFetch());
    setStocksData(stocks);
    return () => {};
  }, []);

  useEffect(() => {
    setStocksData(stocks);
    return () => {
  
    }
  }, [stocks])

  // อนุญาติให้เข้าถึงได้เฉพาะ ผู้จัดการร้าน และ SUPERUSER
  const allowAccess = user
    ? user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
        ? true
        : false
    : history.push("/login");

  if (allowAccess) {
    return (
      <Container fluid className="light-th">
        <div className="container col-md-8 text-center">
          <h2>ประวัติสต๊อกสินค้า</h2>
        </div>
       <br/>
       {stocksData.length === 0 ? (
            <>
              <div className="container col-md-8 text-center text-danger">
                <br />
                <br />
                <br />
                <h2>ไม่พบประวัติสต๊อกวัตถุดิบ</h2>
              </div>
            </>
          ) : (
            <>
             <div className="text-left d-flex">
                <b> ค้นหา </b>{" "}
                <SearchStockProductHistory dataLists={stocks} setData={setStocksData} />
              </div>
              
        <div
          style={{
            maxHeight: "700px",
            overflowY: "auto",
            backgroundColor: "#ffffff",
            boxShadow: "1px 1px 3px #d9d9d9",
          }}
        >
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>วันที่</th>
                <th>เวลา</th>
                
                <th>ชื่อสินค้า</th>
                <th>ผู้แก้ไข</th>
                <th>ชนิด</th>
                <th>จำนวน</th>
                <th>ก่อน</th>
                <th>หลัง</th>
              </tr>
            </thead>
            <tbody>
              {Array.isArray(stocksData) ? (
                <>
                  {" "}
                  {stocksData.map((stock, index) => {
                    return (
                      <TablesStockTransactions
                        key={stock._id}
                        stock={stock}
                        index={index + 1}
                      />
                    );
                  })}
                </>
              ) : (
                <></>
              )}
            </tbody>
          </Table>{" "}
        </div>
        </>
          )}
      </Container>
    );
  } else {
    return (
      <Container className="light-th">
        <div className="container col-md-8 text-center">
          <h2>การจัดการสต๊อกสินค้า</h2>
          <br />
          <h2 className="text-danger ">ขออภัย</h2>

          <h3>
            คุณไม่มีสิทธิในการเข้าถึงการจัดการสต็อกสินค้า <br />
            กรุณาลงชื่อเข้าใช้ด้วยบัญชีที่มีสิทธิ
            <br />
            เป็นผู้จัดการ หรือผู้ดูแลระบบ
          </h3>
        </div>
      </Container>
    );
  }
}

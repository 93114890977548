import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions/";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";

import {
  Container,
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  Label,
} from "reactstrap";

export default function UpdateArticle(props) {
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();
  const article = location.state.article; //ข้อมูลที่ส่งผ่าน history.push
  const categories = useSelector((state) => state.categories);
  const { register, handleSubmit, err } = useForm({
    defaultValues: {
      product_name: article.product_name,
      product_detail: article.product_detail,
      product_unit: article.product_unit,
      product_price: article.product_price,
      category_id: article.categories_id,
    },
  });
  useEffect(() => {
    dispatch(actions.itemFetch(params.id));
    //console.log("UpdateArticle | article", location.state.article);
    return () => {};
  }, []);

  const onEditArticle = (data) => {
    //console.log("data", data);
    dispatch(actions.articleProductUpdate(article.product_id, data));
    history.push("/management/article_product");
  };

  const categoryList = () => {
    if (Array.isArray(categories)) {
      return categories.map((category) => {
        return (
          <option value={category.category_id}>
            {" "}
            {category.category_name}{" "}
          </option>
        );
      });
    }
  };

  return (
    <Container
      style={{
        backgroundColor: "#ffffff",
        boxShadow: "1px 1px 3px #d9d9d9",
        padding: "30px",
        marginTop: "50px",
        maxWidth: "500px",
      }}
      className="light-th "
    >
      <form onSubmit={handleSubmit(onEditArticle)}>
        <Row>
          <Col md="5">
            <h4>เเก้ไขสินค้า</h4>
          </Col>
          <Col
            md="7"
            style={{ fontSize: "12px" }}
            className="text-right mt-1"
          ></Col>
        </Row>

        <hr />
        <br />
        <InputGroup size="lg">
          <Input
            style={{ fontSize: "15px" }}
            type="text"
            placeholder="ชื่อสินค้า"
            name="product_name"
            innerRef={register({
              defaultValues: article.product_name,
            })}
          />
        </InputGroup>
        <br />
        <InputGroup>
          <Label>หมวดหมู่</Label>
          <Col sm={10}>
            <Input
              type="select"
              name="category_id"
              defaultValue={article.category_id}
              innerRef={register}
            >
              {categoryList()}
            </Input>
          </Col>
        </InputGroup>

        <Button
          className="mt-4 btn-info"
          style={{ border: "none" }}
          color="warning"
          size="lg"
          block
          type="submit"
        >
          <span className="light-th" style={{ fontSize: "15px" }}>
            เเก้ไข
          </span>
        </Button>
        <Button
          className="mt-2 "
          style={{ border: "none" }}
          size="lg"
          block
          onClick={() => {
            history.push("/management/article_product");
          }}
        >
          <span className="light-th" style={{ fontSize: "15px" }}>
            ยกเลิก
          </span>
        </Button>
      </form>
    </Container>
  );
}

import React from 'react'
import { Container } from 'reactstrap'

export default function OrderDetail({ order }) {
    const showOrders = () => {
        const date = new Date(order.orderDate)
        return (
            <>
                <div className="row d-flex justify-content-center" >
                    <h6 className="text-center mt-3 mb-3 ">รายการสั่งซื้อวันที่ {date.toLocaleDateString()} {date.toLocaleTimeString()}</h6>
                </div>
                <div className="row d-flex justify-content-center title">
                    {order.orders && order.orders.map(record => {
                        return (
                            <div key={record.product.product_id} className="col-4 text-center ">
                               
                                <img src={record.product.product_image} className="card-img-top img-thumbnail mb-2 rounded mx-auto d-block sticky2 " Style="width: 70px;" alt="..." />
                                <div>
                                    {record.product.product_name}
                                </div>
                                <div >
                                    จำนวน {record.quantity}
                                </div>
                                <div >
                                    ราคา {record.product.product_price * record.quantity}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="row d-flex justify-content-center mb-3">
                    <div className="title  text-danger text-center ">ยอดรวม {order.totalPrice} บาท </div>
                </div>
            </>
        )

    }
    return (
        <Container>
            <div style={{
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 3px #d9d9d9",
                padding: "1px"
            }} >
                {showOrders()}

            </div>
            <br />
        </Container>
    )
}

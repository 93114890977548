import React, { useState } from "react";
import {  useDispatch, useEffect, useSelector } from "react-redux"
import { Table, Container, Row, Col, Button, Input, FormGroup } from "reactstrap";
import { useForm } from "react-hook-form";
import * as actions from "../../../actions";
import ModalAddStocks from "../modal/ModalAddStocks"

const TablesStocktakings = ({ article, index }) => {

  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();
  const user = useSelector((state) => state.user);
  const [comment, setComment] = useState("");

  const onAddStock = (value) => {
    setComment("");
    // console.log("Value")
    // console.log(value);
    value.radio_command = "add"
    dispatch(actions.stockEditAdd(value, user));
    
  };

  const onRemoveStock = (value) => {
    setComment("");
    // console.log("Value")
    // console.log(value);
    value.radio_command = "remove"
    dispatch(actions.stockEditRemove(value, user));

  };


  return (
    <tr>
      {/* <th scope="row">{index}</th> */}
      <td>{article.product_name}</td>
      <td>{article.product_inventory}</td>
      <td>
      {" "}
          <FormGroup className=" d-sm-flex">
            <Col >
              <Input
                type="text"
                name="amount"
                value={comment}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
                innerRef={register}
              />
            </Col>
            <Input 
              type="text" 
              name="product_id" 
              value={article.product_id} 
              innerRef={register} 
              hidden
            />
            <Col>
              <Button
                className="m-1"
                color="success"
                type="button"
                onClick={handleSubmit(onAddStock)}
              >
                เพิ่ม
              </Button>
              <Button
                color="danger"
                type="button"
                onClick={handleSubmit(onRemoveStock)}
              >
                ลบ
              </Button>
            </Col>
          </FormGroup>
      </td>
    </tr>
  );
};

export default TablesStocktakings;

import React, { useEffect } from "react";
import {
    Container,
} from "reactstrap";
import StarRatingComponent from 'react-star-rating-component';
const Article = ({ comments }) => {
    useEffect(() => {
        return () => {
        }
    }, [])
    return (

        <Container>

            {comments.length != 0 ? (
                <div className="d-flex flex-row flex-wrap pt-2 pb-2"
                    style={{
                        backgroundColor: "#ffffff",
                        boxShadow: "1px 1px 3px #d9d9d9",
                    }}>
                    {comments.map(comment => {
                        return (
                            <div key={comment._id} className="col-sm-12 col-md-12 col-lg-6 col-xl-6 ">
                                <div className="card m-1" Style="max-width: 540px;">
                                    <div className="row">
                                        <div className="col-md-3 col-sm-6">
                                            <img src={comment.user_image} className="card-img mt-3 ml-3" alt="..." style={{ width: "35px" }} />
                                        </div>
                                        <div className="col-md-9">
                                            <div className="card-body">
                                                <h5 className="card-title">{comment.user_name}</h5>
                                                <p className="card-text">{comment.comment}</p>
                                                <div className="text-right">
                                                    < StarRatingComponent
                                                        name="rate1"
                                                        starCount={5}
                                                        value={comment.rating}
                                                        renderStarIcon={() => <h2>★</h2>}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            ) : (
                    <div 
                        style={{
                            backgroundColor: "#ffffff",
                            boxShadow: "1px 1px 3px #d9d9d9",
                            padding:"5px"
                        }}>
                        <br/>
                        <p className="text-center">  ยังไม่มีความคิดเห็น</p>
                        <br/>
                    </div>
                )
            }

            <>
                <br/>
            </>

        </Container >



    );
};

export default Article;

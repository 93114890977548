import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions/";
import { Container } from "reactstrap";
import AticleDetail from "./articeldetail/ArticleDetail";
import Rating from "./rating/Rating";
import ComementList from "./comment/CommetLists";
import CommentForm from "./comment/CommentForm";

const Article = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const product = useSelector((state) => state.products);
  const rating = useSelector((state) => state.rating);
  const comments = useSelector((state) => state.comments);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(actions.productFetch(params.id));
    console.log("Article 0 | ", product);
    dispatch(actions.ratingFetch(params.id));
    dispatch(actions.commentFetch(params.id));
    return () => {};
  }, []);
  return (
    <Container className="light-th">
      <AticleDetail
        product={product}
        saleman_id={params.salemanid}
        user={user}
      />
      <Rating rating={rating} />
      <ComementList comments={comments} />
      <CommentForm user={user} product={product} />
    </Container>
  );
};

export default Article;

import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions";
import { useForm } from "react-hook-form";
import { Row, Col, Input, InputGroup, Button, Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import { authen } from "../../../assets/api/firebase";
import * as roles from "../../../util/UserRole";
import * as yup from "yup";

export default function Register({ toggleTopbarVisibility }) {
  const history = useHistory();

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const authData = useSelector((state) => state.auth);
  const [counting, setCounting] = useState(1);
  const [registerVisible, setRegisterVisible] = useState(false);
  const schemaForm = yup.object().shape({
    firstname: yup.string().required("กรุณาใส่ข้อมูล"),
    lastname: yup.string().required("กรุณาใส่ข้อมูล"),
    address: yup.string().required("กรุณาใส่ข้อมูล"),
    phoneNumber: yup.string().required("กรุณาใส่ข้อมูล"),
    email: yup.string().required("กรุณาใส่ข้อมูล"),
  });
  const { register, handleSubmit, errors } = useForm({
    validationSchema: schemaForm,
  });
  useEffect(() => {
    toggleTopbarVisibility(false);
    setCounting(counting + 1);
    return () => {
      toggleTopbarVisibility(true);
    };
  }, []);

  useEffect(() => {
    console.log("Auth State render  " + counting);
    console.log(authData);
    console.log(user);
    console.log("-----------------");
    if (counting === 2) {
      if (!authData && user == null) {
        console.log("Not Found Auth State");
        history.push("/login");
      } else {
        setCounting(counting + 1);
      }
    }
    if (counting === 3) {
      if (authData && user === false) {
        setRegisterVisible(true);
        console.log("No User Found");
      } else if (authData && user) {
        setRegisterVisible(false);
        console.log("Found User");
        history.push("/");
      }
    }

    return () => {};
  }, [counting, user]);

  const onSubmit = (data) => {
    const user_data = {
      user_name: data.firstname + " " + data.lastname,
      user_address: data.address,
      user_tel: data.phoneNumber,
      user_email: data.email,
      id: authData.uid,
      user_image: authData.photoURL,
      role: roles.USER_ROLE,
    };
    dispatch(actions.setUser(user_data));
    toggleTopbarVisibility();
    alert("ลงทะเบียนเสร็จสิ้น")
    history.push("/");
  };

  return (
    <Container
      hidden={!registerVisible}
      style={{
        backgroundColor: "#ffffff",
        boxShadow: "1px 1px 3px #d9d9d9",
        padding: "30px",
        marginTop: "50px",
        maxWidth: "500px",
      }}
    >
      <div
        className="light-th text-danger"
        style={{ minHeight: "70vh", fontSize: "40px" }}
      >
        <hr />
        <Row>
          <Col md="5">
            <h4>ลงทะเบียน</h4>
          </Col>
          <Col md="7" style={{ fontSize: "12px" }} className="text-right mt-2">
            *กรุณากรอกข้อมูลให้ครบถ้วน{" "}
          </Col>
        </Row>

        <hr />
        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="ชื่อภาษาไทย"
              name="firstname"
              innerRef={register}
            />

            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="นามสกุลภาษาไทย"
              name="lastname"
              innerRef={register}
            />
          </InputGroup>
          <div className="d-flex justify-content-center">
            {" "}
            {(errors.firstname || errors.lastname) && (
              <h5 style={{ fontSize: "15px", marginTop: "5px" }}>
                {" "}
                กรุณาใส่ข้อมูลให้ถูกต้อง
              </h5>
            )}
          </div>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="textarea"
              placeholder="ที่อยู่"
              name="address"
              innerRef={register}
            />
          </InputGroup>
          <div className="d-flex justify-content-center">
            {errors.address && (
              <h5 style={{ fontSize: "15px", marginTop: "5px" }}>
                กรุณาใส่ข้อมูลให้ถูกต้อง
              </h5>
            )}
          </div>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="text"
              placeholder="เบอร์โทรศัพท์"
              name="phoneNumber"
              innerRef={register}
            />
          </InputGroup>
          <div className="d-flex justify-content-center">
            {errors.phoneNumber && (
              <h5 style={{ fontSize: "15px", marginTop: "5px" }}>
                กรุณาใส่ข้อมูลให้ถูกต้อง
              </h5>
            )}
          </div>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="email"
              placeholder="Email"
              name="email"
              innerRef={register}
            />
          </InputGroup>
          <div className="d-flex justify-content-center">
            {errors.email && (
              <h5 style={{ fontSize: "15px", marginTop: "5px" }}>
                กรุณาใส่ข้อมูลให้ถูกต้อง
              </h5>
            )}
          </div>

          <Button
            className="mt-4 login-btn"
            style={{ border: "none" }}
            size="lg"
            block
            type="submit"
          >
            <span className="light-th" style={{ fontSize: "15px" }}>
              ลงทะเบียน
            </span>
          </Button>
          <Button
            className="mt-2 btn-danger"
            style={{ border: "none" }}
            size="lg"
            block
            onClick={() => {
              authen.auth().signOut();
              dispatch(actions.authState(null));
              toggleTopbarVisibility(true);
              history.push("/");
            }}
          >
            <span className="light-th" style={{ fontSize: "15px" }}>
              ยกเลิก
            </span>
          </Button>
        </form>
        <br />
      </div>
    </Container>
  );
}
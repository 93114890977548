import { STOCK_FETCH, STOCK_CREATE } from "../actions/types";
export default function (state = [], action) {
  switch (action.type) {
    case STOCK_FETCH:
      return action.payload;
    case STOCK_CREATE:
      return action.payload;
    default:
      return state;
  }
}

import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  Row,
  Col,
  Input,
  InputGroup,
  Button,
  Form,
  Container,
} from "reactstrap";
import StarRatingComponent from "react-star-rating-component";
import * as actions from "../../../actions/";
import * as yup from "yup";

export default function CommentForm({ user, product }) {
  const commentSchema = yup.object().shape({
    comment: yup.string().required("กรุณาใส่ความคิดเห็น"),
  });
  const [rating, setRating] = useState(5);
  const [comment, setComment] = useState("");

  const { register, handleSubmit, errors } = useForm({
    validationSchema: commentSchema,
  });
  const dispatch = useDispatch();
  const onSubmit = (data) => {
    setComment("");
    data.user_id = user.id;
    data.user_image = user.user_image;
    data.user_name = user.user_name;
    data.product_id = product.product_id;
    data.rating = rating;
    dispatch(actions.ratingUpdate(product.product_id, data));
    dispatch(actions.commentPost(data, product.product_id));
  };
  const onStarClick = (nextValue, prevValue) => {
    if (nextValue === prevValue && nextValue === 1) setRating(0);
    else setRating(nextValue);
  };
  return (
    <Container>
      <div
        className="light-th p-1 pl-2 pr-2"
        style={{
          backgroundColor: "#ffffff",
          boxShadow: "1px 1px 3px #d9d9d9",
        }}
      >
        <br />
        <Row>
          <Col md="12">
            <p className="text-center">ระดับคะเเนน</p>
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div className="text-center">
              <StarRatingComponent
                name="rate1"
                starCount={5}
                value={rating}
                renderStarIcon={() => <h1>★</h1>}
                onStarClick={onStarClick}
              />
            </div>
          </Col>
        </Row>

        <form onSubmit={handleSubmit(onSubmit)}>
          <br />
          <InputGroup size="lg">
            <Input
              style={{ fontSize: "15px" }}
              type="textarea"
              placeholder="ความคิดเห็น"
              name="comment"
              value={comment}
              onChange={(event) => {
                setComment(event.target.value);
              }}
              innerRef={register}
            />
          </InputGroup>
          <br />
          <div className="text-center alert-danger">
            {" "}
            {errors.comment && <p>{errors.comment.message}</p>}
          </div>
          <Button
            className="mt-4 login-btn"
            style={{ border: "none" }}
            size="lg"
            block
            type="submit"
          >
            <span style={{ fontSize: "15px" }}>บันทึกความคิดเห็น</span>
          </Button>
        </form>
        <br />
      </div>
      <br />
    </Container>
  );
}

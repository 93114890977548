import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as roles from "../../../util/UserRole";
import * as actions from "../../../actions";
import { Input, FormGroup, Button, Col } from "reactstrap";
import { useForm } from "react-hook-form";

const AllUserTables = ({ user, index, onChangeStatus }) => {
  
  const dispatch = useDispatch();
  const { handleSubmit, register, errors } = useForm();

  const onChangeRole = (value) => {
    console.log(value);
    dispatch(actions.editUserData(value.id, value));
  };

  const deleteUser = (value) => {
    console.log(value.id);
    if (value.role == roles.SUPER_USER_ROLE) {
      window.alert(
        " ห้ามลบผู้ใช้ที่มีสิทธิเป็น Super User ถ้าต้องการโปรดตรวจสอบให้แน่ชัด และแก้ไขผู้ใช้คนดังกล่าวเป็นสถานะอย่างอื่นก่อน"
      );
      return;
    }
    let confirm = window.confirm("ยืนยันการลบผู้ใช้ออกจากระบบ");
    if (confirm) {
      dispatch(actions.deleteUser(value.id));
    }
  };
  return (
    <tr>
      <th scope="row">{index}</th>
      <td> {user.user_name} </td>
      <td> {user.user_email} </td>
      <td>{user.user_tel} </td>
      <td>
        {" "}
        <form>
          <FormGroup className="d-flex">
            <Col lg={6}>
              <Input
                type="select"
                name="role"
                defaultValue={user.role}
                innerRef={register}
              >
                <option> {roles.USER_ROLE} </option>
                {/* <option> {roles.MERCHANT_ROLE} </option> */}
                <option> {roles.ADMIN_ROLE} </option>
                <option> {roles.SUPER_USER_ROLE} </option>
              </Input>
              <Input
                type="text"
                hidden
                name="id"
                value={user.id}
                innerRef={register}
              />
            </Col>
            <Col>
              <Button
                className="m-1"
                color="warning"
                type="button"
                onClick={handleSubmit(onChangeRole)}
              >
                แก้ไข
              </Button>
              <Button
                color="danger"
                type="button"
                onClick={handleSubmit(deleteUser)}
              >
                ลบ
              </Button>
            </Col>
          </FormGroup>{" "}
        </form>
      </td>
    </tr>
  );
};

export default AllUserTables;

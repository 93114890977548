import { createStore, applyMiddleware } from "redux"
import { persistStore, persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import reduxThunk from "redux-thunk"
import reducers from "../reducers/index.js"
import logger from "redux-logger"

const persistConfig = {
  key: "root",
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers, {
  comments: [],
  rating: [],
  auth: [],
  user: [],
  orders: [],
  userManagement: [],
})

export default () => {
  let store = createStore(persistedReducer, applyMiddleware(reduxThunk, logger))
  let persistor = persistStore(store)
  return { store, persistor }
}

// Specify Transaction billed status (Will use in Order Tables)

// Customer Payment
export const PROC_BILLED = "ชำระเงินเเล้ว";

// Merchant Confirm that Payment is Correct (Stock will discount now)
export const PROC_BILL_CORRECT = "ข้อมูลการชำระเงินถูกต้อง";

// Merchant Transit Product to Customer
export const PROC_TRANS = "สินค้ากำลังจัดส่ง";

//Merchant know that product is delivered
export const PROC_COMPLETE = "สินค้าจัดส่งสำเร็จ";

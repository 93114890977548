import React from "react";
import { Container, Card, CardImg } from "reactstrap";

export default function PaymentDetail({ order, user, onChangeStatus }) {
  const showPayment = () => {
    return (
      <>
        <h5 className="text-right mt-2 text-center">ข้อมูลการชำระเงิน</h5>
        <div className="d-flex justify-content-center">
          <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 ">
            <div className="row title">
              <div className="col-12">ชื่อ : {order.user_name}</div>

              <div className="col-12 mt-1">เบอร์โทร : {order.user_tel}</div>

              <div className="col-12 mt-1">ที่อยู่ : {order.user_address}</div>

              <div className="col-12 mt-1">สถานะ : {order.status}</div>
              <div className="col-12 mt-1 mb-4">
                ติดตามสินค้า : {order.TrackingNumber}
              </div>
              <div className="col-12 mt-1 mb-1">หลักฐานการชำระเงิน</div>
              {/* <div className="col-12 mt-2 d-flex justify-content-center mb-2 ">
                {user.id == process.env.ADMIN_ID && (
                  <button
                    className={
                      order.status === "สินค้ากำลังจัดส่ง"
                        ? "btn btn-sm btn-success ml-2 "
                        : "btn btn-sm btn-secondary ml-2"
                    }
                    onClick={() =>
                      this.props.history.push(
                        "/UpdateTrackingNumber/" + order._id
                      )
                    }
                  >
                    เพิ่มหมายเลขติดตามสินค้า
                  </button>
                )}
              </div> */}

              <div className="col-12  mb-3 mt-2">
                <Card>
                  <CardImg
                    top
                    width="100%"
                    src={order.Silp}
                    alt="Card image cap"
                  ></CardImg>
                </Card>
              </div>
              <div className="col-12 mt-2 mb-3">
                {user.id == process.env.REACT_APP_ADMIN_ID && (
                  <div className="d-flex justify-content-center">
                    <button
                      className={
                        order.status === "ข้อมูลการชำระเงินถูกต้อง"
                          ? "btn btn-sm btn-success ml-2 "
                          : "btn btn-sm btn-secondary ml-2"
                      }
                      onClick={() =>
                        onChangeStatus(order, "ข้อมูลการชำระเงินถูกต้อง")
                      }
                    >
                      ถูกต้อง
                    </button>
                    <button
                      className={
                        order.status === "สินค้ากำลังจัดส่ง"
                          ? "btn btn-sm btn-success ml-2 "
                          : "btn btn-sm btn-secondary ml-2"
                      }
                      onClick={() => onChangeStatus(order, "สินค้ากำลังจัดส่ง")}
                    >
                      จัดส่ง
                    </button>
                    <button
                      className={
                        order.status === "สินค้าจัดส่งสำเร็จ"
                          ? "btn btn-sm btn-success ml-2 mr-2 "
                          : "btn btn-sm btn-secondary ml-2 mr-2"
                      }
                      onClick={() =>
                        onChangeStatus(order, "สินค้าจัดส่งสำเร็จ")
                      }
                    >
                      สำเร็จ
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  return (
    <Container>
      <div
        style={{
          backgroundColor: "#ffffff",
          boxShadow: "1px 1px 3px #d9d9d9",
          padding: "1px",
        }}
      >
        {user && showPayment()}
      </div>
      <br />
    </Container>
  );
}

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions/";
import { authen } from "../../../assets/api/firebase";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
} from "reactstrap";
import hash from "object-hash";

const ModalCreateArticle = (props) => {
  const { buttonLabel, className, modal, toggle } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(actions.categoriesProductFetch());
    return () => {};
  }, []);

  const onCreateArticle = (data) => {
    //console.log("data", data);
    // const name = data.product_image[0].name;
    const date = new Date();
    // const pictureName = hash({
    //   name: name,
    //   date: date,
    // });
    // const uploadTask = authen
    //   .storage()
    //   .ref(`images/${pictureName}`)
    //   .put(data.product_image[0]);
    // uploadTask.on(
    //   "state_changed",
    //   (snapshot) => {},
    //   (error) => {
    //     console.log(error);
    //   },
    //   () => {
    //     authen
    //       .storage()
    //       .ref("images")
    //       .child(pictureName)
    //       .getDownloadURL()
    //       .then((url) => {
    //         data.product_image = url;
    //         dispatch(actions.articleCreate(data));
    //         alert("บันทึกสำเร็จ");
    //       });
    //   }
    // );
    dispatch(actions.articleProductCreate(data));
    alert("บันทึกสำเร็จ");
    toggle();
  };
  const categoriesList = () => {
    if (Array.isArray(categories)) {
      return (
        <>
          {categories.map((category) => {
            return (
              <option key={category.category_id} value={category.category_id}>
                {category.category_name}
              </option>
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };
  const changeUnmountOnClose = (e) => {
    let value = e.target.value;
    setUnmountOnClose(JSON.parse(value));
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={unmountOnClose}
      >
        <ModalHeader toggle={toggle}>เพิ่มสินค้า</ModalHeader>
        <ModalBody>
          <form>
            <br />
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="ชื่อสินค้า"
                name="product_name"
                innerRef={register}
              />
            </InputGroup>
            {/* <br />
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                type="textarea"
                placeholder="รายละเอียดสินค้า"
                name="product_detail"
                innerRef={register}
              />
            </InputGroup>
            <br />
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="หน่วยของสินค้า"
                name="product_unit"
                innerRef={register}
              />
              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="ราคาต่อหน่วย"
                name="product_price"
                innerRef={register}
              />
            </InputGroup>
            <br /> */}
            {/* <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="จำนวนสินค้าคงคลัง"
                name="product_inventory"
                innerRef={register}
              />
            </InputGroup> */}

            <br />
            <InputGroup size="md">
              <Input type="select" name="category_id" innerRef={register}>
                {categoriesList()}
              </Input>
            </InputGroup>
            {/* <br />
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                type="file"
                name="product_image"
                innerRef={register}
              />
            </InputGroup> */}
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit(onCreateArticle)}>
            เพิ่ม
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalCreateArticle;

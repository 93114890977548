import React, { useEffect, useState, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import { Table, Container, Row, Col, Button } from "reactstrap";
import { useHistory, useParams, useLocation } from "react-router-dom"
import TablesStocktakingsProduct from "./tables/TablesStocktakingsProduct"
import _ from "lodash";

export default function ManagementStockCat () {
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useParams();
    const history = useHistory();
    const products = useSelector((state) => state.products);
    const category = location.state.category;
    const [article, setArticle] = useState([]);
    const user = useSelector((state) => state.user);

    console.log(article);
    console.log(category);
    console.log("filterArticle",_.find(article, (_article) => {
        return (
            _article.category_id == category.category_id
        )
    }));

    useEffect(() => {
        dispatch(actions.itemsFetch());
        dispatch(actions.stockProductFetch());
        dispatch(actions.categoriesProductFetch());
        return () => {
            
        }
    }, [])

    useEffect(() => {
        setArticle(products)
        return () => { 
        }
    }, [products])

    return (
        <Container fluid className="light-th">
            <div className="container col-md-8 text-center">
                <h2>จัดการสต๊อกสินค้า : {category.category_name}</h2>
            </div>
            <br/>
            <hr/>
            {Array.isArray(article) ? (
                <>
                <div
                    style={{
                        maxHeight: "100vh",
                        overflowY: "auto",
                        backgroundColor: "#ffffff",
                        boxShadow: "1px 1px 3px #d9d9d9",
                      }}
                >
                    <Table striped>
                      <thead>
                          <tr>
                            {/* <th>#</th> */}
                            <th>ชื่อสินค้า</th>
                            <th>ยอดคงเหลือ</th>
                            <th>ดำเนินการ</th>
                          </tr>
                      </thead>
                      <tbody>
                          {article.map((_article, index) => {
                              if (_article.category_id == category.category_id)
                              return (
                                  <TablesStocktakingsProduct
                                    key = {_article.product_id}
                                    article = {_article}
                                    index = {index + 1}
                                  />
                              )
                          })}
                      </tbody>
                    </Table>
                </div>
                </>
            ) : (
                <>
                </>
            )}
        </Container>
    )
}
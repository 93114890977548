import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import * as actions from "../../../actions";
import { useSelector, useDispatch } from "react-redux";
import { Button, Container, Row, Col } from "reactstrap";

const Cart = ({ product, saleman_id }) => {
  const [quantity, setQuantiy] = useState(1);
  const [confirm, setConfirm] = useState(false);
  const [overflow, setOverflow] = useState(false);
  const dispatch = useDispatch();
  const basket = useSelector((state) => state.basket);
  const user = useSelector((state) => state.user);

  const increaseQuantity = () => {
    if (quantity < product.product_inventory) setQuantiy(quantity + 1);
  };
  const decreaseQuantity = () => {
    if (quantity > 1) setQuantiy(quantity - 1);
  };

  const confirmOrder = (product, saleman_id) => {
    if (basket) {
      /*มีตระกร้า*/
      let findOrder = basket.orders.find(
        (order) => order.product.product_id === product.product_id
      );
      if (findOrder) {
        /*มีตระกร้า มีสินค้าอยู่ในตระกร้า*/
        if (
          findOrder.quantity + quantity >
          findOrder.product.product_inventory
        ) {
          /*ตรวจสอบจำนวนสินค้าในตระกร้ากับคลังสินค้า*/
          setConfirm(true);
          setOverflow(true);
          setQuantiy(1);
        } else {
          dispatch(actions.basketConfirm(product, saleman_id, user, quantity));
          setConfirm(true);
          setOverflow(true);
          setQuantiy(1);
        }
      } else {
        /*มีตระกร้า ไม่มีสินค้าอยู่ในตระกร้า*/
        dispatch(actions.basketConfirm(product, saleman_id, user, quantity));
        setConfirm(true);
        setQuantiy(1);
      }
    } else {
      /*ไม่มีตระกร้า ไม่เคยซื้อสินค้า*/
      dispatch(actions.basketConfirm(product, saleman_id, user, quantity));
      setConfirm(true);
      setQuantiy(1);
    }
  };

  useEffect(() => {
    dispatch(actions.basketFetch(user.id));
    console.log("Cart | Basket", basket);
    return () => {};
  }, [user, confirm]);

  return (
    <div>
      <Container className="container-fluid">
        <Row>
          <div className="input-group">
            <Col md="6">
              {" "}
              <h5 className="left">จำนวน </h5>{" "}
            </Col>
            <Col md="6" className="input-group">
              <span className="input-group-btn ">
                <button
                  type="button"
                  className="quantity-left-minus btn btn-secondary btn-number"
                  data-type="minus"
                  data-field=""
                  onClick={() => decreaseQuantity()}
                >
                  <span className="glyphicon glyphicon-minus">-</span>
                </button>
              </span>

              <input
                type="text"
                id="quantity"
                name="quantity"
                className="form-control input-number text-center"
                value={quantity}
              />

              <span className="input-group-btn">
                <button
                  type="button"
                  className="quantity-right-plus btn btn-secondary btn-number mr-2"
                  data-type="plus"
                  data-field=""
                  onClick={() => increaseQuantity()}
                >
                  <span className="glyphicon glyphicon-plus">+</span>
                </button>
              </span>
            </Col>
          </div>
        </Row>
        <br />
        <Row>
          <Col></Col>
          <Col sm="12" md="12" lg="6">
            {" "}
            <Button
              color="danger"
              block
              onClick={() => confirmOrder(product, saleman_id)}
            >
              เพิ่มลงตระกร้า
            </Button>
          </Col>
        </Row>
        <Row>
          {/* แจ้งเตือนเพิ่มสินค้าลงในตะกร้า*/}
          {confirm && (
            <div className="container m-2">
              <div className="alert alert-success text-center " role="alert">
                <span className="title col-12  text-center">
                  ได้ทำการเพิ่มสินค้าลงตะกร้าแล้ว กรุณาตรวจสอบที่
                  <Link className="text-danger" to="/cart">
                    รถเข็นสินค้า
                  </Link>
                </span>
              </div>
            </div>
          )}
        </Row>
      </Container>
    </div>
  );
};
export default Cart;

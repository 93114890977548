import React, { useState } from 'react'
import Topbar from "./Topbar"
import {
    Container,
} from "reactstrap";


const NotFound = ({ toggleSidebar }) => {
    const [topbarIsOpen, setTopbarOpen] = useState(true);
    const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
    return (
        <Container fluid fluid style={{ minHeight: "80vh" }}>
            
            <div className="container col-md-8 text-center" >
                <h1 style={{ fontSize: 120 }}>404</h1>
                <h2>Not Found</h2>
                <p className="title">ขออภัยไม่พบหน้าที่คุณค้นหา ดูเหมือนว่าหน้าเว็บคุณพยายามเข้าถึงหน้าที่ไม่มีอยู่อีกต่อไปหรืออาจจะย้ายไปเว็บอื่น</p>
            </div>
        </Container>
    )

}
export default NotFound
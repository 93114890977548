import axios from "axios";
import { FETCH_USER, EDIT_USER } from "./types";

export const fetchAllUser = () => {
  return (dispatch) => {
    console.log("Get All User Detail |");

    axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
      dispatch({ type: FETCH_USER, payload: res.data });
    });
  };
};

export const editUserData = (uid, value) => {
  return (dispatch) => {
    console.log("Edit User Role ");
    axios
      .put(process.env.REACT_APP_API_URL + "/user/" + uid, value)
      .then(() => {
        console.log("Request to Put Send Successfully");
        axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
          window.alert("Edit User Role Success");
          dispatch({ type: EDIT_USER, payload: res.data });
        });
      })
      .catch((err) => {
        console.log("Error");
        console.error(err);
      });
  };
};

export const deleteUser = (uid) => {
  return (dispatch) => {
    console.log("Delete User");
    axios
      .delete(process.env.REACT_APP_API_URL + "/user/" + uid)
      .then((res) => {
        axios.get(process.env.REACT_APP_API_URL + "/user/all/").then((res) => {
          window.alert("Delete User Success");
          dispatch({ type: EDIT_USER, payload: res.data });
        });
      })
      .catch((err) => {
        window.alert("ลบไม่สำเร็จ");
        console.err(err);
      });
  };
};

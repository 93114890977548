import React from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "react-calendar/dist/Calendar.css";
import { Container, Row, Col } from "reactstrap";

const SearchStockHistory = (props) => {
  const { dataLists, setData } = props;
  const onChange = (date) => {
    var startDate = new Date(date[0]);
    var endDate = new Date(date[1]);
    var result = dataLists.filter((data) => {
      let dataDate = new Date(data.date);
      return dataDate >= startDate && dataDate <= endDate;
    });
    setData(result);
  };

  return (
    <div>
      <Container className="col-md-auto">
        <Row>
          <Col md="4">
            {" "}
            <DateRangePicker
              format="dd/MM/y"
              dayPlaceholder="วัน"
              monthPlaceholder="เดือน"
              yearPlaceholder="ปี"
              rangeDivider="ถึง"
              locale={"th-TH"}
              onChange={onChange}
            />
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
};
export default SearchStockHistory;

import React, { useState, useEffect } from 'react'

import {
    Container,
    Row,
    Col
} from "reactstrap";
import StarRatingComponent from 'react-star-rating-component';

const Rating = ({ rating }) => {
    useEffect(() => {
        console.log("Rating :", rating);

        return () => {

        }
    }, [])
    return (
        <Container>
            <div
                style={{
                    backgroundColor: "#ffffff",
                    boxShadow: "1px 1px 3px #d9d9d9",
                }}
            >
                <p className="pl-4 pt-3">คะแนนของสินค้า</p>
                <div className="px-4">
                    <div
                        style={{
                            backgroundColor: "#fffbf8",
                            border: "2px solid #f9ede5",
                        }}
                    >
                        <br />


                        {rating != null ? (
                            <Row>
                                <Col md="6" style={{ textAlign: "center" }}>
                                    <div style={{ fontSize: "30px", color: "#d93731" }}>
                                        {rating.average} / 5  คะเเนน
                                    </div>
                                </Col>
                                <Col md="6">
                                    {/* ระดับคะแนนรวมของสินค้า */}
                                    <div className="d-flex justify-content-center">
                                        {rating != null && (
                                            <StarRatingComponent
                                                name="rate1"
                                                starCount={5}
                                                value={rating.average}
                                                renderStarIcon={() => <h1>★</h1>}
                                            />
                                        )}
                                    </div>
                                </Col>
                            </Row>

                        ) : (
                                <Row>
                                    <Col md="12" style={{ textAlign: "center" }}>
                                        <div style={{ fontSize: "20px", color: "#d93731" }}>
                                            <p className="light-th" style={{ textAlign: "center" }}>
                                                <br />
                                 ยังไม่มีการให้คะเเนนสินค้า
                                             </p>
                                        </div>
                                    </Col>
                                

                                </Row>

                            )}
                        <br />
                    </div>
                    <br />
                    <hr />
                </div>
            </div>
        </Container>

    )

}
export default Rating;

import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions";
import {
  Container,
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  CardSubtitle,
} from "reactstrap";
import { useHistory } from "react-router-dom";

const CategoriesStockProductLists = (props) => {
  const { category } = props;
  const history = useHistory();
  const dispatch = useDispatch();

  // console.log(category);

  useEffect(() => {
    dispatch(actions.itemsFetch())
    dispatch(actions.categoriesProductFetch())
    return () => {
     
    }
  }, [])

  const goToProductFromCategory = (category) => {
    history.push({
      pathname: "/management/stock_product/" + category.category_id,
      state: { category : category }
    })
  }

  const showCategoriesLists = () => {
    return (
      category && category.map((_category) => {
        return (
          <Col
            xs="6"
            sm="6"
            md="4"
            lg="2"
            className="p-1"
            maxHeight="250px"
            key={_category.category_id}
          >
            <Card
              style={{ cursor: "pointer" }} 
              className="mb-1"
              onClick = {() => goToProductFromCategory(_category)}
            >
              <CardBody>
                <CardTitle 
                // className="text-info"
                >
                  {_category.category_name}
                {/* <strong>{_category.category_name}</strong>   */}
                </CardTitle>
                <CardSubtitle className="text-left text-muted pt-2">
                  <t>คลิกเพื่อจัดการ</t>
                </CardSubtitle>
              </CardBody>
            </Card>
          </Col>
        )
      })
    )
  }

  return category ? (
    <>
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <hr/>
        {category.length ? (
          <>
          {" "}
          <Container fluid>
            <Row>
              {showCategoriesLists()}
            </Row>
          </Container>
          </>
        ) : (
          <>
            <div
                className="light-th text-danger"
                style={{ textAlign: "center", fontSize: "20px" }}
              >
                <br />
                ยังไม่มีรายการสินค้า
              </div>
          </>
        )}

      </div>
    </>
  ) : (
    <>
      <div
        className="light-th text-danger"
        style={{
          textAlign: "center",
          fontSize: "20px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <br />
        โทเค็นไม่ถูกต้อง
      </div>
    </>
  )

};

export default CategoriesStockProductLists;

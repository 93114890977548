import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Container,
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  Table,
  Button,
} from "reactstrap";
import * as role from "../../../util/UserRole";
import * as actions from "../../../actions";
import ModalCreateCategory from "../modal/ModalCreateCategoryProduct";
import ModalEditCategoryName from "../modal/ModalEditCategoryProductName";
import Categories from "../../../components/categories/CategoriesProduct";
import { useHistory } from "react-router-dom"

const ManagementCategory = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const categories = useSelector((state) => state.categories);
  const products = useSelector((state) => state.products);

  // Inside State Setting
  const [selectedCategory, setSelectedCategory] = useState("");
  const [modalCreateCategory, setModalCreateCategory] = useState(false);
  const [modalEditCategoryName, setModalEditCategoryName] = useState(false);

  // Modal Setting
  const toggleModalCreateCategory = () => {
    setModalCreateCategory(!modalCreateCategory);
  };
  const togleModalEditCategoryName = () => {
    setModalEditCategoryName(!modalEditCategoryName);
  };

  // Use Effect (ComponentDidMount)
  useEffect(() => {
    dispatch(actions.categoriesProductFetch());
    return () => {

    }
  }, []);

  // Access Control
  const allowAccess = user
    ? user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE
        ? true
        : false
    : history.push("/login");

  // On User change category in left menu
  const selectNewCategory = (value) => {
      console.log("data", value.category_name);
    setSelectedCategory({
      category_name: value.category_name,
      category_id: value.category_id,
    });
    dispatch(actions.itemsFetchFromCategory(value.category_id));
  };

  // Lisitng Category list to left menu (In style of list menu)
  const categoryList = () => {
    if (Array.isArray(categories)) {
      return categories.map((category) => {
        return (
          <ListGroupItem
            tag="a"
            href="#"
            action
            active={selectedCategory.category_id == category.category_id}
            onClick={() => {
              selectNewCategory(category);
            }}
          >
            {category.category_name}
          </ListGroupItem>
        );
      });
    } else {
      console.log("Category is not Array");
    }
  };

  // Fetch Product List for each Category (in style of Table)
  const productList = () => {
    if (Array.isArray(products)) {
      return products.map((product, index) => {
        return (
          <tr>
            <th> {index + 1} </th>
            <td> {product.product_name} </td>
            <td> {product.product_inventory} </td>
          </tr>
        );
      });
    }
  };

  // Delete Category
  const deleteCategory = (categoryId) => {
    console.log(categoryId);
    let confim = window.confirm("ยืนยันที่จะลบหมวดหมู่นี้หรือไม่");
    if (confim) {
      dispatch(actions.deleteCategoryProduct(categoryId));
    } else {
      console.log("ยกเลิกการลบ");
    }
  };

  // Render User Interface
  return (
    <Container fluid className="light-th">
      <div className=" text-center">
        <ModalCreateCategory
          modal={modalCreateCategory}
          toggle={toggleModalCreateCategory}
        />

        <ModalEditCategoryName
          modal={modalEditCategoryName}
          toggle={togleModalEditCategoryName}
          category={selectedCategory}
        />

        <h2>จัดการหมวดหมู่สินค้า</h2>

        {allowAccess ? (
          <>
            <Row>
              <Col className="text-left">
                {/* <Categories categories={categories} /> */}
              </Col>
              <Col className="text-right">
                <Button color="success" onClick={toggleModalCreateCategory}>
                  {" "}
                  เพิ่มหมวดหมู่{" "}
                </Button>
              </Col>
            </Row>{" "}
            <hr />
            <Row>
              <Col sm="4" lg="4" className="text-left">
                <h3>รายชื่อหมวดหมู่ </h3>
                <ListGroup>{categoryList()}</ListGroup>
              </Col>
              <Col sm="8" lg="8" className="text-left">
                {selectedCategory == "" ? (
                  <>
                    <p> กรุณาเลือกหมวดหมู่ที่ต้องการจะแก้ไข</p>
                  </>
                ) : (
                  <>
                    <h3> {selectedCategory.category_name} </h3>
                    <Row>
                      <Col className="text-left">
                        <p>
                          สินค้าจำนวน {products.length} ชิ้น{" "}
                          {products.length != 0 ? (
                            <>
                              <br /> การจะลบหมวดหมู่ได้
                              หมวดหมู่นั้นจะต้องไม่มีสินค้าก่อน
                            </>
                          ) : (
                            ""
                          )}{" "}
                        </p>
                      </Col>
                      <Col className="text-right ">
                        <Button
                          className="m-1"
                          color="warning"
                          onClick={togleModalEditCategoryName}
                        >
                          {" "}
                          เปลี่ยนชื่อ
                        </Button>

                        <Button
                          color="danger"
                          disabled={products.length != 0}
                          onClick={() =>
                            deleteCategory(selectedCategory.category_id)
                          }
                        >
                          ลบ
                        </Button>
                      </Col>
                    </Row>

                    <p> </p>

                    <Table>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>ชื่อสินค้า</th>
                          <th>จำนวน</th>
                        </tr>
                      </thead>
                      <tbody>{productList()}</tbody>
                    </Table>
                  </>
                )}
              </Col>
            </Row>
          </>
        ) : (
          <>
            <h1 className="text-danger"> ขออภัย </h1>
            <h3>
              คุณไม่มีสิทธิในการเข้าถึงระบบจัดการสินค้า <br />
              กรุณาลงทะเบียนด้วย User ที่ได้รับสิทธิเป็นผู้ดูแลระบบ
            </h3>
          </>
        )}
      </div>
    </Container>
  );
};

export default ManagementCategory;

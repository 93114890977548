import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions/";
import { authen } from "../../../assets/api/firebase";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
} from "reactstrap";
import hash from "object-hash";

const ModalCreateArticle = (props) => {
  const { buttonLabel, className, modal, toggle } = props;
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const categories = useSelector((state) => state.categories);

  useEffect(() => {
    dispatch(actions.categoriesFetch());
    return () => {};
  }, []);

  const onCreateArticle = (data) => {
   
    const date = new Date();
    
    dispatch(actions.articleCreate(data));
    alert("บันทึกสำเร็จ");
    toggle();
  };
  const categoriesList = () => {
    if (Array.isArray(categories)) {
      return (
        <>
          {categories.map((category) => {
            return (
              <option key={category.category_id} value={category.category_id}>
                {category.category_name}
              </option>
            );
          })}
        </>
      );
    } else {
      return "";
    }
  };
  const changeUnmountOnClose = (e) => {
    let value = e.target.value;
    setUnmountOnClose(JSON.parse(value));
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={unmountOnClose}
      >
        <ModalHeader toggle={toggle}>เพิ่มวัตถุดิบ</ModalHeader>
        <ModalBody>
          <form>
            <br />
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="ชื่อวัตถุดิบ"
                name="product_name"
                innerRef={register}
              />
            </InputGroup>

            <br />
            <InputGroup size="md">
              <Input type="select" name="category_id" innerRef={register}>
                {categoriesList()}
              </Input>
            </InputGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit(onCreateArticle)}>
            เพิ่ม
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalCreateArticle;

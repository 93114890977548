import axios from "axios";
import {
  CATEGORIES_FETCH,
  CATEGORY_CREATE,
  CATEGORY_EDIT,
  CATEGORY_REMOVE,
} from "./types";

//วัตถุดิบ
export const categoriesFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/category").then((res) => {
      dispatch({ type: CATEGORIES_FETCH, payload: res.data });
    });
  };
};

//สินค้า
export const categoriesProductFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL_PRODUCT + "/category").then((res) => {
      dispatch({ type: CATEGORIES_FETCH, payload: res.data });
    });
  };
};

//วัตถุดิบ
export const createCategory = (data) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/category", data)
      .then((res) => {
        dispatch({ type: CATEGORY_CREATE, payload: res.data });
      })
      .catch((err) => {
        console.log("Error Creating Category");
        console.error(err);
      });
  };
};

//สินค้า
export const createCategoryProduct = (data) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL_PRODUCT + "/category", data)
      .then((res) => {
        dispatch({ type: CATEGORY_CREATE, payload: res.data });
      })
      .catch((err) => {
        console.log("Error Creating Category");
        console.error(err);
      });
  };
};

//วัตถุดิบ
export const editCategoryName = (data) => {
  console.log(data);
  return (dispatch) => {
    axios
      .put(
        process.env.REACT_APP_API_URL + "/category/" + data.category_id,
        data
      )
      .then((res) => {
        alert("แก้ไขชื่อสำเร็จ");
        dispatch({ type: CATEGORY_CREATE, payload: res.data });
        window.location.reload();
      })
      .catch((err) => {
        alert("ไม่สามารถเปลี่ยนชื่อหมวดหมู่ได้");
        console.log("Cannot Edit Category Name");
        console.error(err);
      });
  };
};

//สินค้า
export const editCategoryProductName = (data) => {
  console.log(data);
  return (dispatch) => {
    axios
      .put(
        process.env.REACT_APP_API_URL_PRODUCT + "/category/" + data.category_id,
        data
      )
      .then((res) => {
        alert("แก้ไขชื่อสำเร็จ");
        dispatch({ type: CATEGORY_CREATE, payload: res.data });
        window.location.reload();
      })
      .catch((err) => {
        alert("ไม่สามารถเปลี่ยนชื่อหมวดหมู่ได้");
        console.log("Cannot Edit Category Name");
        console.error(err);
      });
  };
};

//วัตถุดิบ
export const deleteCategory = (categoryId) => {
  console.log("User Request to delete Category | ", categoryId);
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/category/" + categoryId)
      .then((res) => {
        axios.get(process.env.REACT_APP_API_URL + "/category/").then((res) => {
          dispatch({ type: CATEGORY_REMOVE, payload: res.data });
          window.location.reload();
        });
      })
      .catch((err) => {
        alert("ลบหมวดหมู่ไม่สำเร็จ");
        console.log("Error Remove Category");
        console.error(err);
      });
  };
};

//สินค้า
export const deleteCategoryProduct = (categoryId) => {
  console.log("User Request to delete Category | ", categoryId);
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_API_URL_PRODUCT + "/category/" + categoryId)
      .then((res) => {
        axios.get(process.env.REACT_APP_API_URL_PRODUCT + "/category/").then((res) => {
          dispatch({ type: CATEGORY_REMOVE, payload: res.data });
          window.location.reload();
        });
      })
      .catch((err) => {
        alert("ลบหมวดหมู่ไม่สำเร็จ");
        console.log("Error Remove Category");
        console.error(err);
      });
  };
};
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as actions from "../../../actions/";
import { useForm } from "react-hook-form";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
} from "reactstrap";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { stockCreate } from "../../../actions/";

const ModalAddStock = (props) => {
  const { className, modal, toggle, product } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  const article = useSelector((state) => state.products);
  
  
  // const onEditArticle = (data) => {
  //   //console.log("data", data);
  //   dispatch(actions.articleUpdate(article.product_id, data));
  //   history.push("/management/article");
  // };
  const onAddStock = (data) => {
    toggle();
    // const replaceStock = {
    //   product_id : product.product_id,
    //   product_name : product.product_name,
    // };

    // axios
    //   .get(process.env.REACT_APP_API_URL + "/product/" + data.product_id)
    //   .then((res) => {
    //     //console.log("Response", res.data);
    //     const product_inventory_update = {
    //       //next_quantity
    //       product_inventory:
    //         parseInt(res.data.product_inventory) + // prev_quantity
    //         parseInt(data.product_inventory), //เเปลงให้เป็น int ก่อน //quantity
    //     };
    //     axios
    //       .put(
    //         process.env.REACT_APP_API_URL + "/product/" + data.product_id,
    //         product_inventory_update
    //       ) //อัพเดตค่าสินค้าคงคลัง
    //       .then(() => {
    //         const stock = {
    //           stock_date: new Date().toLocaleDateString(),
    //           stock_time: new Date().toLocaleTimeString(),
    //           type: "เข้า",
    //           article_id: res.data.product_id,
    //           article_name: res.data.product_name,
    //           quantity: data.product_inventory,
    //           prev_quantity: res.data.product_inventory,
    //           next_quantity: product_inventory_update.product_inventory,
    //           article_unit: res.data.product_unit,
    //         };
    //         axios
    //           .post(process.env.REACT_APP_API_URL + "/stock", stock)
    //           .then(() => {

    //             alert("บันทึกสำเร็จ");
    //             dispatch(actions.productsFetch());
    //             dispatch(actions.stockFetch());
    //           });
    //       });
    //   });

   
    // dispatch(actions.productsFetch());
    // dispatch(actions.editCategoryName(replaceStock));
    dispatch(actions.stockCreate(data));
    // history.push("/menagement/stock");
    
   
    // alert("บันทึกสำเร็จ");
  

  };

  useEffect(() => {
    
    return () => { };
  }, []);

  const articleList = () => {
    return (
      <>
        {article.map((_article) => {
          return (
            <option key={_article.product_id} value={_article.product_id}>
              {_article.product_name}
            </option>
          );
        })}
      </>
    );
  };
  return (
    <div>
      <Modal 
        isOpen={modal} 
        toggle={toggle} 
        className={className}>
        <ModalHeader toggle={toggle}>
          เพิ่มสต๊อกสินค้า  
        </ModalHeader>
        <ModalBody>
          <form>
            {/* <br />
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                type="select"
                name="product_id" //เเสดงผลเป็นชื่อสินค้า เเต่ค่าที่ใช้ในการรับส่งข้อมูลจะเป็น product_id
                innerRef={register}
              >
                {articleList()}
              </Input>
            </InputGroup>
            <br /> */}
            <InputGroup size="lg">
              <Input
                style={{ fontSize: "15px" }}
                type="text"
                placeholder="จำนวนสินค้าคงคลัง"
                name="product_inventory"
                innerRef={register}
              />
            </InputGroup>
          </form>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit(onAddStock)}>
            เพิ่ม
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalAddStock;

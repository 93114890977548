import React, { useState } from "react";
import {
  Row,
  Container,
  Col,
  Card,
  CardImg,
  CardBody,
  CardTitle,
  CardSubtitle,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";

const ArticleList = ({ article }) => {
  const history = useHistory();
  
  const goToDetail = (article_id) => {
    history.push("product/" + article_id + "/saleman_id");
  };

  const showArticleList = () => {
    if (Array.isArray(article))
    return (
      article && article.map((article) => {
        return (
          <Col
            xs="6"
            sm="6"
            md="4"
            lg="2"
            className="p-1"
            key={article.product_id}
            maxHeight="250px"
          >
            <Card
              style={{ cursor: "pointer" }}
              className="mb-1"
              // onClick={() => goToDetail(article.product_id)}
            >
              <CardBody>
                <CardTitle>
                  {" "}
                  <t>{article.product_name}</t>
                </CardTitle>
                <CardSubtitle className="text-left text-muted pt-2">
                  <t>วัตถุดิบคงเหลือ {article.product_inventory}</t>
                </CardSubtitle>
              </CardBody>
            </Card>
          </Col>
        );
      })
    );
  };
  return article ? (
    <>
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <hr />
        {article.length ? (
          <>
            {" "}
            <Container fluid>
              <Row>{showArticleList()}</Row>
            </Container>
          </>
        ) : (
          <>
            <div
              className="light-th text-danger"
              style={{ textAlign: "center", fontSize: "20px" }}
            >
              <br />
              ยังไม่มีรายการวัตถุดิบ
            </div>
          </>
        )}
      </div>
    </>
  ) : (
    <>
      <div
        className="light-th text-danger"
        style={{
          textAlign: "center",
          fontSize: "20px",
          backgroundColor: "#f5f5f5",
        }}
      >
        <br />
        โทเค็นของร้านค้าไม่ถูกต้อง
      </div>
    </>
  );
};

export default ArticleList;

// To Specify Role name that will use in Database

// Access All Area
export const SUPER_USER_ROLE = "SUPERUSER"

// Access Stock + Frontstore + Product Editing
export const ADMIN_ROLE = "ADMIN"

// Access Frontstore + Product Editing
export const MERCHANT_ROLE = "MERCHANT"

// Access Only Frontstore
export const USER_ROLE = "USER"

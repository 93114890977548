import React, { useEffect, useState } from "react";
import {
    Container, Card, CardImg,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../actions/"
import ArticleLists from "./article/ArticleLists";
import Categories from "../components/categories/Categories"
import * as role from "../util/UserRole"
import { useHistory } from "react-router-dom"

const Home = () => {
    const dispatch = useDispatch()
    const history = useHistory();
    const products = useSelector(state => state.products);
    const categories = useSelector(state => state.categories);
    const user = useSelector((state) => state.user);
    const [article, setArticle] = useState([]);
    const [categoryName, setCategoryName] = useState("");
    const allowAccess = user ? (user.role == role.ADMIN_ROLE || user.role == role.SUPER_USER_ROLE ? true : false) : false;

    useEffect(() => {
        console.log("Welcome Home");
        dispatch(actions.productsFetch())
        dispatch(actions.categoriesFetch())
        setArticle(products);
        return () => {
        }
    }, [])

    useEffect(() => {
        setArticle(products)
        return () => {

        };
    }, [products]);


    if (allowAccess) {
        return (
            <Container fluid style={{ minHeight: "80vh" }} className="light-th">
                <div className="text-center">
                    <h2 >สถานะวัตถุดิบ {categoryName ? categoryName : ''}</h2>
                </div>
                <div className="text-left">
                    <Categories
                        categories={categories}
                        article={products}
                        setArticle={setArticle}
                        setCategoryName={setCategoryName}
                    />
                </div>
                <ArticleLists article={article} />
            </Container>
        );
    } else {
        return (
            <Container fluid className="light-th">
                <div className="container col-md-8 text-center">
                    <br />
                    <br />
                    <h2> <strong> ยินดีต้อนรับสู่ BENJAPAO</strong>

                    </h2>
                    <br />
                    <img
                        src={require('../assets/images/benjapao.jpg')}
                        width="30%"
                        height="100%"
                    >
                    </img>
                </div>
            </Container>
        )
    }

};

export default Home;

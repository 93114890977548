import React, { useEffect } from "react";
import classNames from "classnames";
import { Container } from "reactstrap";
import { Switch, Route, BrowserRouter } from "react-router-dom";

import Topbar from "./Topbar";
import Footer from "./Footer";
import Login from "../../containers/login/Login";
import Register from "../../containers/login/register/Register";
import RegisterFromEmail from "../../containers/login/register/RegisterFromBlank";
import Home from "../../containers/Home";
import HomeProduct from "../../containers/HomeProduct";
import Article from "../../containers/article/Article";
import Status from "../../containers/status/Status";
import NotFound from "./NotFound";
import Cart from "../../containers/cart/Cart";
import Payment from "../../containers/payment/Payment";
import ManagementOrders from "../../containers/management/ManagementOrders";
import ManagementStocks from "../../containers/management/ManagementStocks";
import ManagementStockCat from "../../containers/management/ManagementStockCat"
import ManagementArticle from "../../containers/management/ManagementArticle";
import ManagementStocksProduct from "../../containers/management/ManagementStocksProduct";
import ManagementStockProductCat from "../../containers/management/ManagementStockProductCat"
import ManagementArticleProduct from "../../containers/management/ManagementArticleProduct";
import UpdateArticle from "../../containers/management/updateArticle/UpdateArticle";
import UpdateArticleProduct from "../../containers/management/updateArticle/UpdateArticleProduct";
import ManagementUser from "../../containers/management/ManagementUser";
import ManagementCategory from "../../containers/management/categories/ManagementCategory";
import StockHistory from "../../containers/management/history/StockHistory";
import ManagementCategoryProduct from "../../containers/management/categories/ManagementCategoryProduct";
import StockProductHistory from "../../containers/management/history/StockProductHistory";

const Content = ({
  topbarIsOpen,
  toggleTopbar,
  toggleTopbarVisibility,
  topbarVisibility,
}) => {
  useEffect(() => {
    // console.log("Content | topbarVisibility ",topbarVisibility);

    return () => {};
  }, []);
  return (
    // <BrowserRouter basename="/benjapao">
    <Container
      fluid
      className={classNames("content", { "is-open": topbarIsOpen })}
    >
      <Topbar
        toggleTopbar={toggleTopbar}
        topbarIsOpen={topbarIsOpen}
        topbarVisibility={topbarVisibility}
      />
      <Switch>
        
        <Route 
          exact 
          path="/" 
          render={() => <Home />} 
        />

        <Route 
          exact 
          path="/status_product" 
          render={() => <HomeProduct />} 
        />

        <Route
          exact
          path="/product/:id/:salemanid"
          render={() => <Article />}
        />

        <Route 
          exact 
          path="/login" 
          render={() => <Login/>}
          // component={Login} 
          />

        <Route
          exact
          path="/login/register"
          render={() => (
            <Register
              toggleTopbarVisibility={toggleTopbarVisibility}
              topbarVisibility={topbarVisibility}
            />
          )}
        />
        <Route
          exact
          path="/login/email-register"
          render={() => <RegisterFromEmail />}
        />
        <Route exact path="/cart" render={() => <Cart />} />
        <Route exact path="/status" render={() => <Status />} />
        <Route exact path="/payment/:id" render={() => <Payment />} />
        <Route
          exact
          path="/management/order"
          render={() => <ManagementOrders />}
        />
        <Route
          exact
          path="/management/article"
          render={() => <ManagementArticle />}
        />

        <Route
          exact
          path="/management/article_product"
          render={() => <ManagementArticleProduct />}
        />

        <Route
          exact
          path="/management/stock"
          render={() => <ManagementStocks />}
        />

        <Route
          exact
          path="/management/stock/:id"
          render={() => <ManagementStockCat />}
        />

        <Route
          exact
          path="/management/article/update/:id"
          render={() => <UpdateArticle />}
        />
        
        <Route
          exact
          path="/management/article/update_product/:id"
          render={() => <UpdateArticleProduct />}
        />

        <Route
          exact
          path="/management/user"
          render={() => <ManagementUser />}
        />

        <Route
          exact
          path="/management/category"
          render={() => <ManagementCategory />}
        />

        <Route
          exact
          path="/management/category_product"
          render={() => <ManagementCategoryProduct />}
        />

        <Route
          exact
          path="/management/article_product"
          render={() => <ManagementArticleProduct />}
        />

        <Route
          exact
          path="/management/stock_product"
          render={() => <ManagementStocksProduct />}
        />

        <Route
          exact
          path="/management/stock_product/:id"
          render={() => <ManagementStockProductCat />}
        />

        <Route
          exact
          path="/history/stock_product"
          render={() => <StockProductHistory />}
        />
        
        <Route 
          exact 
          path="/history/stock" 
          render={() => <StockHistory />} 
        />

        {/* <Route exact path="/about" component={() => "About"} />
      <Route exact path="/Pages" component={() => "Pages"} />
      <Route exact path="/faq" component={() => "FAQ"} />
      <Route exact path="/contact" component={() => "Contact"} />
      <Route exact path="/Home-1" component={() => "Home-1"} />
      <Route exact path="/Home-2" component={() => "Home-2"} />
      <Route exact path="/Home-3" component={() => "Home-3"} />
      <Route exact path="/Page-1" component={() => "Page-1"} />
      <Route exact path="/Page-2" component={() => "Page-2"} />
      
      <Route exact path="/page-2" component={() => "page-2"} />
      <Route exact path="/page-3" component={() => "page-3"} />
      <Route exact path="/page-4" component={() => "page-4"} /> */}
        <Route render={() => <NotFound />} />
      </Switch>
      {/* <Footer/> */}
    </Container>
    // </BrowserRouter>
  );
};

export default Content;

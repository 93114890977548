import axios from "axios";
import {
  ORDERS_WAIT_PAYMENT,
  ORDERS_PAYMENT,
  ORDER_PAYMENT_FETCH,
  ORDER_RESET,
  ORDERS_PAID,
  ORDERS_PAID_DELETE,
  STOCK_CREATE,
  PRODUCTS_FETCH,
  ORDER_UPDATE,
} from "./types";

import * as roles from "../util/UserRole";
import * as TransactionStatus from "../util/TransactionStatus";
import { useHistory } from "react-router-dom";

/**
 * EDIT STRUCTURE - 2020-03-07
 * ใช้ Allow Access กำหนดสิทธืของหน้าจัดการสินค้าให้สำหรับ Merchant / Admin และ Superuser
 * เปลี่ยนจากการรับ UID มาเป็นรับ Object User
 */

/* ***************** ORDER MANAGEMENT ********************* */
export const ordersWaitPaymentFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/order/" + id).then((res) => {
      dispatch({ type: ORDERS_WAIT_PAYMENT, payload: res.data });
    });
  };
};

export const ordersPaidFetch = (user) => {
  let uid = user.id;

  const allowAccess =
    user.role == roles.MERCHANT_ROLE ||
    user.role == roles.ADMIN_ROLE ||
    user.role == roles.SUPER_USER_ROLE
      ? true
      : false;

  if (uid == process.env.REACT_APP_ADMIN_ID || allowAccess) {
    return (dispatch) => {
      axios.get(process.env.REACT_APP_API_URL + "/order/all").then((res) => {
        dispatch({ type: ORDERS_PAID, payload: res.data });
      });
    };
  } else {
    return (dispatch) => {
      axios
        .get(process.env.REACT_APP_API_URL + "/order/user/" + uid)
        .then((res) => {
          console.log("res", res);
          dispatch({ type: ORDERS_PAID, payload: res.data });
        });
    };
  }
};

export const orderPaidDelete = (id, uid) => {
  return (dispatch) => {
    axios.delete(process.env.REACT_APP_API_URL + "/order/" + id).then((res) => {
      axios.get(process.env.REACT_APP_API_URL + "/order/" + uid).then((res) => {
        dispatch({ type: ORDERS_PAID_DELETE, payload: res.data });
      });
    });
  };
};

export const ordersPaymentPut = (id, values) => {
  return (dispatch) => {
    axios
      .put(process.env.REACT_APP_API_URL + "/order/" + id, values)
      .then((res) => {
        dispatch({ type: ORDERS_PAYMENT });
      });
  };
};

/** ************STORE MANAGEMENT IN ALL CONDITION************************** */

export const ordersPaymentStatusPut = (id, values, user) => {
  // id คือ order _id
  // Non uid PoK3aDRlXeYhtHWBg1sMYSZIOHo1

  const allowAccess =
    user.role == roles.MERCHANT_ROLE ||
    user.role == roles.ADMIN_ROLE ||
    user.role == roles.SUPER_USER_ROLE
      ? true
      : false;

  let uid = user.id;

  if (uid == process.env.REACT_APP_ADMIN_ID || allowAccess) {
    return (dispatch) => {
      axios
        .put(process.env.REACT_APP_API_URL + "/order/" + id, values)
        .then((res) => {
          //console.log("Order Status Update | req",values);
          axios
            .get(process.env.REACT_APP_API_URL + "/order/" + res.data._id) //ดึงรายการที่อัพเดตใหม่เเล้ว
            .then((res) => {
              if (res.data.status === TransactionStatus.PROC_BILL_CORRECT) {
                handlingCompletePayment(res, dispatch);
              } else if (res.data.status == TransactionStatus.PROC_COMPLETE) {
                handlingSuccessTransportation(res, dispatch);
              }

              axios
                .get(process.env.REACT_APP_API_URL + "/order/all")
                .then((res) => {
                  dispatch({ type: ORDERS_PAID, payload: res.data });
                });
            });
        });
    };
  }

  // else {
  //   return (dispatch) => {
  //     axios
  //       .put(process.env.REACT_APP_API_URL + "/order/" + id, values)
  //       .then((res) => {
  //         axios
  //           .get(process.env.REACT_APP_API_URL + "/order/" + uid)
  //           .then((res) => {
  //             dispatch({ type: ORDERS_PAID, payload: res.data });
  //           });
  //       });
  //   };
  // }
};

export const orderPaymentFetch = (id) => {
  // ตอนนี้ทุก order จะส่งมาที่เดียวกันเพราะยังมีการการ login เพื่อระบุตัวตน user
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/order/" + id).then(
      //ต้องแก้โดยการส่งไปที่ DB ของ user แต่ละคน หลังจากนั้นจะดึง ข้อมูลของ User แต่ละคนมาแสดงว่ายืนยันรายการอะไรไปแล้วมั้ง
      (res) => {
        dispatch({ type: ORDER_PAYMENT_FETCH, payload: res.data });
      }
    );
  };
};

export const ordersReset = () => {
  return (dispatch) => {
    dispatch({ type: ORDER_RESET });
  };
};

/********* STORE MANAGEMENT ON SPECIFIC CONDITION  **************************** */

export const handlingCompletePayment = (res, dispatch) => {
  //map จำนวนของสินค้าในเเต่ละ order
  res.data.orders.map((order) => {
    const order_product_id = order.product.product_id;
    axios
      .get(process.env.REACT_APP_API_URL + "/product/" + order_product_id)
      .then((res) => {
        //get จำนวนสินค้าคงคลัง เพื่อมาลบกับที่ขายไปได้
        const product_inventory_update = {
          product_inventory: res.data.product_inventory - order.quantity,
        };
        return axios
          .put(
            process.env.REACT_APP_API_URL + "/product/" + order_product_id,
            product_inventory_update
          ) //อัพเดตค่าสินค้าคงคลัง
          .then(() => {
            const stock = {
              stock_date: new Date().toLocaleDateString(),
              stock_time: new Date().toLocaleTimeString(),
              type: "ออก",
              article_id: order_product_id,
              article_name: res.data.product_name,
              quantity: order.quantity, //(ค่าสต๊อกที่เพิ่มเข้ามา)
              prev_quantity: res.data.product_inventory, // (ค่าเดิม)
              next_quantity: product_inventory_update.product_inventory, // next_quantity (ค่าสต๊อกเมื่ออัพเดต)
              article_unit: res.data.product_unit,
            };
            axios
              .post(process.env.REACT_APP_API_URL + "/stock", stock) // post ค่าสต๊อก
              .then(() => {
                axios
                  .get(process.env.REACT_APP_API_URL + "/product/all")
                  .then((res) => {
                    dispatch({
                      type: PRODUCTS_FETCH,
                      payload: res.data,
                    });
                    axios
                      .get(process.env.REACT_APP_API_URL + "/stock/all")
                      .then((res) => {
                        dispatch({
                          type: STOCK_CREATE,
                          payload: res.data,
                        });
                        console.log("ตัดสต๊อก");
                      });
                  });
              });
          });
      });
  });
};

export const handlingSuccessTransportation = (res, dispatch) => {
  const orderId = res.data._id;
  console.log("Order ID | ", orderId);
  axios
    .get(process.env.REACT_APP_API_URL + "/log/keep/" + orderId)
    .then((res) => {
      console.log("เก็บข้อมูลไว้ใน Log แล้ว | ", res);
    });
};

export const handlingTransportation = (res, dispatch) => {
  const order = res.data;
};

export const trackingNumberAdding = (order, trackingNo) => {
  return (dispatch) => {
    console.log(order);
    order.TrackingNumber = trackingNo;
    axios
      .put(process.env.REACT_APP_API_URL + "/order/" + order._id, order)
      .then((res) => {
        // dispatch({ type: ORDER_UPDATE, payload: res.data });
      });
  };
};

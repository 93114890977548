import React from "react";
import { Button } from "reactstrap";

const TablesStockTransactions = ({ stock, index }) => {

  const date = new Date(stock.date);

  return (
    <tr>
      <th scope="row">{index}</th>
      {/* <td style={{ fontSize: "14px" }}>{stock._id}</td> */}
      <td>{date.toLocaleDateString('en-GB')} </td>
      <td>{date.toLocaleTimeString()} </td>
      
      <td>{stock.article_name}</td>
      <td>{stock.stock_user}</td>
      <td>{stock.type}</td>
      <td>
        {stock.quantity} 
      </td>
      <td>
        {stock.prev_quantity} 
      </td>
      <td>
        {stock.next_quantity} 
      </td>
    </tr>
  );
};

export default TablesStockTransactions;

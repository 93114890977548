// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
//

// ReactDOM.render(<App />, document.getElementById("root"));
// registerServiceWorker();

import "bootstrap/dist/css/bootstrap.min.css"
import "./assets/css/app.css"
import React from "react"
import ReactDOM from "react-dom"
import { PersistGate } from "redux-persist/integration/react"
import App from "./App"
import { Provider } from "react-redux"
//import all reducers
import registerServiceWorker from "./registerServiceWorker"
import configureStore from "./store/store.js"

const { store, persistor } = configureStore()
// const {store} = configureStore();
ReactDOM.render(
  <div style={{ backgroundColor: "#f5f5f5" }}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div>
          <App />
        </div>
      </PersistGate>
    </Provider>
  </div>,
  document.getElementById("root")
)
registerServiceWorker()
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();

import React from 'react'
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions/"
import { Container, Row } from 'reactstrap';

export default function DisplayCart({ basket }) {
    const dispatch = useDispatch()
    const user = useSelector(state => state.user);
    const showOrders = () => {
        const date = new Date(basket.orderDate);
        return (
            <div className="col-md-12 col-sm-12 ">
                <div className="mb-4">
                    <h5 className="text-center mt-3 mb-3 ">รายการสั่งซื้อวันที่ {date.toLocaleDateString()}{" "}{date.toLocaleTimeString()}</h5>
                    <div className="row d-flex justify-content-center">
                        {basket.orders && basket.orders.map((record) => {
                            // {console.log("DisplayCart | record  ",record) }
                            // {console.log("DisplayCart | user  ",user.id) }
                            return ( 
                                <div key={record.product.product_id} className="">
                                    <div className=" row  ">
                                        <div className="col-md-3 col-lg-3 mt-4">
                                            <h6 className="text-center title ">{record.product.product_name}</h6>
                                            <img src={record.product.product_image} className=" card-img-top img-thumbnail mb-2  rounded mx-auto d-block " Style="width: 100px;" alt="..." />
                                        </div>
                                      
                                        <div className="col-md-6 col-sm-6 mt-4">
                                            <h6 className="text-center ">จำนวน </h6>
                                            <div className="container input-group d-flex justify-content-center">
                                                <span className="input-group-btn ">
                                                    <button type="button" className="quantity-left-minus btn btn-outline-dark btn-number" data-type="minus" data-field="" onClick={() => { dispatch(actions.basketEditQuantity(record.product.product_id,user.id, "-")) }}>
                                                        <span className="glyphicon glyphicon-minus">-</span>
                                                    </button>
                                                </span>
                                                <input type="text" id="quantity" name="quantity" className="form-control input-number  col-5 text-center" value={record.quantity} min="1" max="10" />
                                                <span className="input-group-btn">
                                                    <button type="button" className="quantity-right-plus btn btn-outline-dark btn-number mr-2" data-type="plus" data-field="" onClick={() => { dispatch(actions.basketEditQuantity(record.product.product_id,user.id, "+", record.quantity, record.product.product_inventory) ) }}>
                                                        <span className="glyphicon glyphicon-plus">+</span>
                                                    </button>
                                                </span>

                                            </div>
                                        </div>
                                        <div className="col-md-3 col-lg-3 mt-4">
                                            <h6 className="text-center title ">ราคา : {record.product.product_price * record.quantity}{" "}บาท</h6>
                                            <div className="d-flex justify-content-center">
                                                <button className="btn btn-danger mb-2 " onClick={() => dispatch(actions.basketDeleteProduct(basket._id, record.product.product_id,user.id))}>ลบ</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container"><hr /></div>
                                </div>
                            );
                        })}
                    </div>


                </div>
            </div>
        );
    }
    return (
        <Container >
            <div style={{
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 3px #d9d9d9",
            }} >
                <Row>
                    {basket && showOrders()}
                </Row>

            </div>
            <br />
        </Container>
    )
}

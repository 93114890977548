import axios from "axios";
import { STOCK_FETCH, STOCK_CREATE, PRODUCTS_FETCH, STOCK_EDIT } from "./types";

//วัตถุดิบ
export const stockFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/stock/all").then((res) => {
      // console.log("Response",res);
      dispatch({ type: STOCK_FETCH, payload: res.data });
    });
  };
};

export const stockIdFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/stock/" + id).then((res) => {
      // console.log("Response",res);
      dispatch({ type: STOCK_FETCH, payload: res.data });
    });
  };
};

//สินค้า
export const stockProductFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL_PRODUCT + "/stock/all").then((res) => {
      // console.log("Response",res);
      dispatch({ type: STOCK_FETCH, payload: res.data });
    });
  };
};

export const stockProductIdFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL_PRODUCT + "/stock/" + id).then((res) => {
      // console.log("Response",res);
      dispatch({ type: STOCK_FETCH, payload: res.data });
    });
  };
};


//วัตถุดิบ
export const stockFetchFromCategory = (id) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/stock/category/" + id)
      .then((res) => {
        dispatch({ type: STOCK_FETCH, payload: res.data });
      });
  };
};

//สินค้า
export const stockProductFetchFromCategory = (id) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL_PRODUCT + "/stock/category/" + id)
      .then((res) => {
        dispatch({ type: STOCK_FETCH, payload: res.data });
      });
  };
};

//วัตถุดิบ
export const stockCreate = (values) => {
  //ส่งค่าของ product ที่จะทำการเพิ่มสต๊อกเข้ามา
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/" + values.product_id) //เพื่อดึงค่า product_inventory
      .then((res) => {
        const product_inventory_update = {
          //next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          product_inventory:
            parseInt(res.data.product_inventory) + // prev_quantity (ค่าเดิม)
            parseInt(values.product_inventory), //เเปลงให้เป็น int ก่อน //quantity (ค่าสต๊อกที่เพิ่มเข้ามา)
        };
        axios
          .put(
            process.env.REACT_APP_API_URL_PRODUCT + "/product/" + values.product_id,
            product_inventory_update
          ) //อัพเดตค่า product_inventory (สินค้าคงคลัง)
          .then(() => {
            const stock = {
              stock_date: new Date().toLocaleDateString(),
              stock_time: new Date().toLocaleTimeString(),
              type: "เพิ่ม",
              article_id: res.data.product_id,
              article_name: res.data.product_name,
              category_id: res.data.category_id,
              category_name: res.data.category_name,
              quantity: values.product_inventory, //(ค่าสต๊อกที่เพิ่มเข้ามา)
              prev_quantity: res.data.product_inventory, // (ค่าเดิม)
              next_quantity: product_inventory_update.product_inventory, // next_quantity (ค่าสต๊อกเมื่ออัพเดต)
              article_unit: res.data.product_unit,
            };
            axios
              .post(process.env.REACT_APP_API_URL_PRODUCT + "/stock", stock) // post ค่าสต๊อก
              .then(() => {
                axios
                  .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/all")
                  .then((res) => {
                    dispatch({ type: PRODUCTS_FETCH, payload: res.data });
                    axios
                      .get(process.env.REACT_APP_API_URL_PRODUCT + "/stock/all")
                      .then((res) => {
                        dispatch({ type: STOCK_CREATE, payload: res.data });
                      });
                  });
              });
          });
      });
  };
};

//สินค้า
export const stockProductCreate = (values) => {
  //ส่งค่าของ product ที่จะทำการเพิ่มสต๊อกเข้ามา
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/" + values.product_id) //เพื่อดึงค่า product_inventory
      .then((res) => {
        const product_inventory_update = {
          //next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          product_inventory:
            parseInt(res.data.product_inventory) + // prev_quantity (ค่าเดิม)
            parseInt(values.product_inventory), //เเปลงให้เป็น int ก่อน //quantity (ค่าสต๊อกที่เพิ่มเข้ามา)
        };
        axios
          .put(
            process.env.REACT_APP_API_URL_PRODUCT + "/product/" + values.product_id,
            product_inventory_update
          ) //อัพเดตค่า product_inventory (สินค้าคงคลัง)
          .then(() => {
            const stock = {
              stock_date: new Date().toLocaleDateString(),
              stock_time: new Date().toLocaleTimeString(),
              type: "เพิ่ม",
              article_id: res.data.product_id,
              article_name: res.data.product_name,
              category_id: res.data.category_id,
              category_name: res.data.category_name,
              quantity: values.product_inventory, //(ค่าสต๊อกที่เพิ่มเข้ามา)
              prev_quantity: res.data.product_inventory, // (ค่าเดิม)
              next_quantity: product_inventory_update.product_inventory, // next_quantity (ค่าสต๊อกเมื่ออัพเดต)
              article_unit: res.data.product_unit,
            };
            axios
              .post(process.env.REACT_APP_API_URL_PRODUCT + "/stock", stock) // post ค่าสต๊อก
              .then(() => {
                axios
                  .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/all")
                  .then((res) => {
                    dispatch({ type: PRODUCTS_FETCH, payload: res.data });
                    axios
                      .get(process.env.REACT_APP_API_URL_PRODUCT + "/stock/all")
                      .then((res) => {
                        dispatch({ type: STOCK_CREATE, payload: res.data });
                      });
                  });
              });
          });
      });
  };
};

//เพิ่มวัตถุดิบ
export const stockEditAdd = (values, user) => {

  console.log("data", user);

  return (dispatch) => {
    const command = values.radio_command;
    const productId = values.product_id;
    const amount = parseInt(values.amount);
    console.log("In Action")
    console.log(command+" "+ productId + " " + amount );
    let newInventory;
    axios
      .get(process.env.REACT_APP_API_URL + "/product/" + productId)
      .then((res) => {
        const current = parseInt(res.data.product_inventory);
        console.log(current)
        if (command == "remove") {
          newInventory = current - amount;
        } else if (command == "add") {
          newInventory = current + amount;
        } else if (command == "change") {
          newInventory = amount;
        } else {
          alert("กรุณาเลือกกระบวนการอย่างใดอย่างหนึ่ง");
        }
        const stock = {
          // stock_date: new Date().toLocaleDateString('en-GB'),
          // stock_time: new Date().toLocaleTimeString(),
          type: "เพิ่ม",
          article_id: productId,
          article_name: res.data.product_name,
          category_id: res.data.category_id,
          category_name: res.data.category_name,
          quantity: amount, //(ค่าสต๊อกที่เพิ่มเข้ามา)
          prev_quantity: current, // (ค่าเดิม)
          next_quantity: newInventory, // next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          article_unit: res.data.product_unit,
          stock_user: user.user_name,
        };
        const product_inventory_update = {
          //next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          product_inventory: newInventory,
        };
        axios
          .put(
            process.env.REACT_APP_API_URL + "/product/" + productId,
            product_inventory_update
          )
          .then(() => {
            axios
              .post(process.env.REACT_APP_API_URL + "/stock", stock) // post ค่าสต๊อก
              .then(() => {
                axios
                  .get(process.env.REACT_APP_API_URL + "/product/all")
                  .then((res) => {
                    dispatch({ type: PRODUCTS_FETCH, payload: res.data });
                    axios
                      .get(process.env.REACT_APP_API_URL + "/stock/all")
                      .then((res) => {
                        dispatch({ type: STOCK_CREATE, payload: res.data });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        console.log("Error!!")
        console.error(err);
      });
  };
};

//ลดวัตถุดิบ
export const stockEditRemove = (values, user) => {
  return (dispatch) => {
    const command = values.radio_command;
    const productId = values.product_id;
    const amount = parseInt(values.amount);
    console.log("In Action")
    console.log(command+" "+ productId + " " + amount );
    let newInventory;
    axios
      .get(process.env.REACT_APP_API_URL + "/product/" + productId)
      .then((res) => {
        const current = parseInt(res.data.product_inventory);
        console.log(current)
        if (command == "remove") {
          newInventory = current - amount;
        } else if (command == "add") {
          newInventory = current + amount;
        } else if (command == "change") {
          newInventory = amount;
        } else {
          alert("กรุณาเลือกกระบวนการอย่างใดอย่างหนึ่ง");
        }
        const stock = {
          // stock_date: new Date().toLocaleDateString('en-GB'),
          // stock_time: new Date().toLocaleTimeString(),
          type: "ลด",
          article_id: productId,
          article_name: res.data.product_name,
          category_id: res.data.category_id,
          category_name: res.data.category_name,
          quantity: amount, //(ค่าสต๊อกที่เพิ่มเข้ามา)
          prev_quantity: current, // (ค่าเดิม)
          next_quantity: newInventory, // next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          article_unit: res.data.product_unit,
          stock_user: user.user_name,
        };
        const product_inventory_update = {
          //next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          product_inventory: newInventory,
        };
        axios
          .put(
            process.env.REACT_APP_API_URL + "/product/" + productId,
            product_inventory_update
          )
          .then(() => {
            axios
              .post(process.env.REACT_APP_API_URL + "/stock", stock) // post ค่าสต๊อก
              .then(() => {
                axios
                  .get(process.env.REACT_APP_API_URL + "/product/all")
                  .then((res) => {
                    dispatch({ type: PRODUCTS_FETCH, payload: res.data });
                    axios
                      .get(process.env.REACT_APP_API_URL + "/stock/all")
                      .then((res) => {
                        dispatch({ type: STOCK_CREATE, payload: res.data });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        console.log("Error!!")
        console.error(err);
      });
  };
};

//เพิ่มสินค้า
export const stockProductEditAdd = (values, user) => {

  return (dispatch) => {

    console.log("data", user);

    const command = values.radio_command;
    const productId = values.product_id;
    const categoryID = values.category_id;
    const amount = parseInt(values.amount);
    console.log("In Action")
    console.log(command+" "+ productId + " " + amount );
    let newInventory;
    axios
      .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/" + productId)
      .then((res) => {
        const current = parseInt(res.data.product_inventory);
        console.log(current)
        if (command == "remove") {
          newInventory = current - amount;
        } else if (command == "add") {
          newInventory = current + amount;
        } else if (command == "change") {
          newInventory = amount;
        } else {
          alert("กรุณาเลือกกระบวนการอย่างใดอย่างหนึ่ง");
        }
        const stock = {
          // stock_date: new Date().toLocaleDateString('en-GB'),
          // stock_time: new Date().toLocaleTimeString(),
          type: "เพิ่ม",
          article_id: productId,
          article_name: res.data.product_name,
          category_id: res.data.category_id,
          category_name: res.data.category_name,
          quantity: amount, //(ค่าสต๊อกที่เพิ่มเข้ามา)
          prev_quantity: current, // (ค่าเดิม)
          next_quantity: newInventory, // next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          article_unit: res.data.product_unit,
          stock_user: user.user_name,
        };
        const product_inventory_update = {
          //next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          product_inventory: newInventory,
        };
        axios
          .put(
            process.env.REACT_APP_API_URL_PRODUCT + "/product/" + productId,
            product_inventory_update
          )
          .then(() => {
            axios
              .post(process.env.REACT_APP_API_URL_PRODUCT + "/stock", stock) // post ค่าสต๊อก
              .then(() => {
                axios
                  .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/all")
                  .then((res) => {
                    dispatch({ type: PRODUCTS_FETCH, payload: res.data });
                    axios
                      .get(process.env.REACT_APP_API_URL_PRODUCT + "/stock/all" )
                      .then((res) => {
                        dispatch({ type: STOCK_CREATE, payload: res.data });
                        // stockProductFetch();
                        
                      });
                  });
              });
          });
      })
      .catch((err) => {
        console.log("Error!!")
        console.error(err);
      });
  };
};

//ลดสินค้า
export const stockProductEditRemove = (values, user) => {
  return (dispatch) => {
    const command = values.radio_command;
    const productId = values.product_id;
    const amount = parseInt(values.amount);
    console.log("In Action")
    console.log(command+" "+ productId + " " + amount );
    let newInventory;
    axios
      .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/" + productId)
      .then((res) => {
        const current = parseInt(res.data.product_inventory);
        console.log(current)
        if (command == "remove") {
          newInventory = current - amount;
        } else if (command == "add") {
          newInventory = current + amount;
        } else if (command == "change") {
          newInventory = amount;
        } else {
          alert("กรุณาเลือกกระบวนการอย่างใดอย่างหนึ่ง");
        }
        const stock = {
          // stock_date: new Date().toLocaleDateString('en-GB'),
          // stock_time: new Date().toLocaleTimeString(),
          type: "ลด",
          article_id: productId,
          article_name: res.data.product_name,
          category_id: res.data.category_id,
          category_name: res.data.category_name,
          quantity: amount, //(ค่าสต๊อกที่เพิ่มเข้ามา)
          prev_quantity: current, // (ค่าเดิม)
          next_quantity: newInventory, // next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          article_unit: res.data.product_unit,
          stock_user: user.user_name,
        };
        const product_inventory_update = {
          //next_quantity (ค่าสต๊อกเมื่ออัพเดต)
          product_inventory: newInventory,
        };
        axios
          .put(
            process.env.REACT_APP_API_URL_PRODUCT + "/product/" + productId,
            product_inventory_update
          )
          .then(() => {
            axios
              .post(process.env.REACT_APP_API_URL_PRODUCT + "/stock", stock) // post ค่าสต๊อก
              .then(() => {
                axios
                  .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/all")
                  .then((res) => {
                    dispatch({ type: PRODUCTS_FETCH, payload: res.data });
                    axios
                      .get(process.env.REACT_APP_API_URL_PRODUCT + "/stock/all")
                      .then((res) => {
                        dispatch({ type: STOCK_CREATE, payload: res.data });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        console.log("Error!!")
        console.error(err);
      });
  };
};


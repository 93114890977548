import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../actions/"
import DisplayCart from "./display/DisplayCart"
import DisplayConfirm from "./display/DisplayConfirm"
import { Link } from "react-router-dom";
import {
    Container,
} from "reactstrap";

const Cart = () => {
    const history = useHistory();
    const dispatch = useDispatch()
    const user = useSelector(state => state.user)
    const basket = useSelector(state => state.basket)

    useEffect(() => {
        if (user == null)
            history.push('/login')
        else
            dispatch(actions.basketFetch(user.id))
        return () => {

        }
    }, [])
    return (
        <Container style={{ minHeight: "100vh" }} className="light-th">
            <div className="container col-md-8 text-center " >
                {/* <h1 style={{ fontSize: 120 }}>404</h1> */}
                <h2>รายการสินค้าในรถเข็น</h2>
            </div>
            {basket != null ? (<>
                <DisplayCart basket={basket} />
                <DisplayConfirm basket={basket} history={history} /></>
            ) : (<>
                <div className="light-th container col-md-8 text-center" >
                    <br />
                    <br />
                    <br />
                    <h2 >ไม่พบสินค้าในรถเข็นของท่าน</h2>
                    <h2> กลับไป<Link className="text-danger" to="/">เลือกสินค้า</Link></h2>
                </div>
            </>)

            }

        </Container>
    );
};
export default Cart;

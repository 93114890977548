export const ORDER_RESET = "order_reset";
export const ORDER_CONFIRM = "order_confirm";
export const ORDER_CONFIRM_FETCH = "order_confirm_fetch";

export const ORDERS_WAIT_PAYMENT = "orders_wait_payment";
export const ORDERS_PAID = "orders_paid";
export const ORDERS_PAID_DELETE = "orders_paid_delete";
export const ORDERS_PAYMENT = "orders_payment";
export const ORDERS_PAYMENT_STATUS_UPDATE = "orders_payment_status_update";

export const ORDERS_PAYMENT_FETCH = "orders_payment_fetch";
export const ORDER_PAYMENT_FETCH = "order_payment_fetch";
export const ORDER_PAYMENT_DELETE = "order_payment_delete";
export const ORDER_UPDATE = "order_update";

export const PRODUCTS_FETCH = "products_fetch";

export const PRODUCT_FETCH = "product_fetch";
export const ARTICLE_DELETE = "article_delete";
export const ARTICLE_CREATE = "article_create";
export const ARTICLE_UPDATE = "article_update";

export const PRODUCT_CREATE = "product_create";
export const PRODUCT_UPDATE = "product_update";

export const CATEGORIES_FETCH = "categories_fetch";
export const CATEGORY_CREATE = "category_create";
export const CATEGORY_EDIT = "category_edit";
export const CATEGORY_REMOVE = "category_remove";

export const PAYMENT_POST = "payment_post";
export const PAYMENT_FETCH = "payment_fetch";

export const SET_USER = "set_user";
export const GET_USER = "get_user";
export const RESET_USER = "reset_user";
export const FETCH_USER = "fetch_user";
export const EDIT_USER = "edit_user";

export const COMMENT_POST = "comment_post";
export const COMMENT_FETCH = "comment_fetch";

export const RATING_FETCH = "rating_fetch";
export const RATING_FETCH2 = "rating_fetch2";

export const BASKET_POST = "basket_post";

export const AUTH_STATE = "auth_state";

export const STOCK_FETCH = "stock_fetch";
export const STOCK_CREATE = "stock_create";
export const STOCK_EDIT = "stock_edit";

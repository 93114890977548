import axios from "axios";
import {
  PRODUCTS_FETCH,
  PRODUCT_FETCH,
  ARTICLE_CREATE,
  ARTICLE_UPDATE,
  ARTICLE_DELETE,
} from "./types";

//วัตถุดิบ
export const productFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/product/" + id).then((res) => {
      dispatch({ type: PRODUCT_FETCH, payload: res.data });
    });
  };
};

//สินค้า
export const itemFetch = (id) => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL_PRODUCT + "/product/" + id).then((res) => {
      dispatch({ type: PRODUCT_FETCH, payload: res.data });
    });
  };
};


//วัตถุดิบ
export const productsFetchFromCategory = (id) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL + "/product/category/" + id)
      .then((res) => {
        dispatch({ type: PRODUCTS_FETCH, payload: res.data });
      });
  };
};

//สินค้า
export const itemsFetchFromCategory = (id) => {
  return (dispatch) => {
    axios
      .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/category/" + id)
      .then((res) => {
        dispatch({ type: PRODUCTS_FETCH, payload: res.data });
      });
  };
};

//วัตถุดิบ
export const productsFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL + "/product/all").then((res) => {
      // console.log("Response",res);
      dispatch({ type: PRODUCTS_FETCH, payload: res.data });
    });
  };
};

//สินค้า
export const itemsFetch = () => {
  return (dispatch) => {
    axios.get(process.env.REACT_APP_API_URL_PRODUCT + "/product/all").then((res) => {
      // console.log("Response",res);
      dispatch({ type: PRODUCTS_FETCH, payload: res.data });
    });
  };
};

//วัตถุดิบ เพิ่มวัตถุดิบ
export const articleCreate = (values) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL + "/product", values)
      .then((res) => {
        //console.log("articleCreate | res",res);
        axios
          .get(process.env.REACT_APP_API_URL + "/product/all")
          .then((res) => {
            dispatch({ type: ARTICLE_CREATE, payload: res.data });
          });
      });
  };
};

//สินค้า เพิ่มสินค้า
export const articleProductCreate = (values) => {
  return (dispatch) => {
    axios
      .post(process.env.REACT_APP_API_URL_PRODUCT + "/product", values)
      .then((res) => {
        //console.log("articleCreate | res",res);
        axios
          .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/all")
          .then((res) => {
            dispatch({ type: ARTICLE_CREATE, payload: res.data });
          });
      });
  };
};


//วัตถุดิบ เเก้ไขวัตถุดิบ
export const articleUpdate = (id, values) => { //id คือ product id
  return (dispatch) => {
    axios
      .put(process.env.REACT_APP_API_URL + "/product/" + id, values)
      .then((res) => {
        axios
          .get(process.env.REACT_APP_API_URL + "/product/all")
          .then((res) => {
            dispatch({ type: ARTICLE_UPDATE, payload: res.data });
          });
      });
  };
};

//สินค้า เเก้ไขสินค้า
export const articleProductUpdate = (id, values) => { //id คือ product id
  return (dispatch) => {
    axios
      .put(process.env.REACT_APP_API_URL_PRODUCT + "/product/" + id, values)
      .then((res) => {
        axios
          .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/all")
          .then((res) => {
            dispatch({ type: ARTICLE_UPDATE, payload: res.data });
          });
      });
  };
};

//วัตถุดิบ ลบวัตถุดิบ
export const articleDelete = (id) => {
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_API_URL + "/product/" + id)
      .then((res) => {
        axios
          .get(process.env.REACT_APP_API_URL + "/product/all")
          .then((res) => {
            dispatch({ type: ARTICLE_DELETE, payload: res.data });
          });
      });
  };
};

//สินค้า ลบสินค้า
export const articleProductDelete = (id) => {
  return (dispatch) => {
    axios
      .delete(process.env.REACT_APP_API_URL_PRODUCT + "/product/" + id)
      .then((res) => {
        axios
          .get(process.env.REACT_APP_API_URL_PRODUCT + "/product/all")
          .then((res) => {
            dispatch({ type: ARTICLE_DELETE, payload: res.data });
          });
      });
  };
};

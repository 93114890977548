import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as actions from "../../../actions";
import { useForm } from "react-hook-form";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  InputGroup,
  Input,
  ModalFooter,
  Button,
} from "reactstrap";

const ModalCreateCategory = (props) => {
  const dispatch = useDispatch();
  const { className, modal, toggle } = props;
  const [unmountOnClose, setUnmountOnClose] = useState(true);
  const { register, handleSubmit, err } = useForm();

  const onCreateCategory = (data) => {
    toggle();
    console.log(data);
    dispatch(actions.createCategory(data));
    dispatch(actions.categoriesFetch());
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        unmountOnClose={unmountOnClose}
      >
        <ModalHeader toggle={toggle}>เพิ่มหมวดหมู่</ModalHeader>
        <ModalBody>
          <from>
            <Label>ใส่ชื่อหมวดหมู่วัตถุดิบ</Label>
            <InputGroup size="lg">
              <Input
                type="text"
                style={{ fontSize: "15px" }}
                placeholder="ชื่อหมวดหมู่"
                name="category_name"
                innerRef={register}
              />
            </InputGroup>
          </from>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSubmit(onCreateCategory)}>
            เพิ่ม
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            ยกเลิก
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ModalCreateCategory;

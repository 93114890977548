import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBirthdayCake, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { authen } from "../../assets/api/firebase";
import * as actions from "../../actions";

import {
  Navbar,
  Button,
  NavbarToggler,
  Collapse,
  Nav,
  NavItem,
  NavLink,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import * as roles from "../../util/UserRole";

const Topbar = ({ toggleTopbar, topbarIsOpen, topbarVisibility }) => {
  // const [topbarIsOpen, setTopbarOpen] = useState(false);
  const user = useSelector((state) => state.user);
  // { console.log(" Topbar | user ", user); }
  //const toggleTopbar = () => setTopbarOpen(!topbarIsOpen);
  const history = useHistory();
  let merchantAccess;
  let adminAccess;
  let superuserAccess;

  if (!user) {
    merchantAccess = false;
    adminAccess = false;
    superuserAccess = false;
  } else {
    merchantAccess =
      user.role == roles.MERCHANT_ROLE ||
      user.role == roles.ADMIN_ROLE ||
      user.role == roles.SUPER_USER_ROLE
        ? true
        : false;
    adminAccess =
      user.role == roles.ADMIN_ROLE || user.role == roles.SUPER_USER_ROLE
        ? true
        : false;
    superuserAccess = user.role == roles.SUPER_USER_ROLE ? true : false;
  }

  useEffect(() => {

    // history.push("/login");

    return () => {};
  }, [topbarVisibility]);

  return (
    <>
      {/* {console.log("topbarVisibility", topbarVisibility)} */}
      {topbarVisibility && (
        <Navbar
          color="light"
          light
          className="navbar shadow-sm p-3 mb-5 bg-white rounded sticky-top"
          expand="md"
        >
          <img className="mr-1"
            src = { require('../../assets/images/benjapao.jpg') }
            width = "80vw"
       
          >
          </img>
          <Link to="/status_product">
            <h2 className="shadow-sm p-2 bg-light rounded">
              BENJAPAO</h2>
          </Link>

          <NavbarToggler onClick={toggleTopbar} />
          <Collapse isOpen={topbarIsOpen} navbar>
            <Nav className="ml-auto" navbar>

                

                

              {user != null ? (
                <>

                

               
                    {/*********** MERCHANT, ADMIN and SUPER USER can ACCESS ***********/}
                    {adminAccess == true  ? (
                      <>
                      
                      <NavItem>
                  <NavLink tag={Link} to={"/status_product"}>
                    สถานะสินค้า
                  </NavLink>
                </NavItem>

                <NavItem>
                  <NavLink tag={Link} to={"/"}>
                    สถานะวัตถุดิบ
                  </NavLink>
                </NavItem>
                      
                      <NavItem>
                      <NavLink tag={Link} to={"/management/stock_product" }>
                          จัดการสต๊อกสินค้า
                      </NavLink>
                    </NavItem>

                    <NavItem>
                      <NavLink tag={Link} to={"/management/stock" }>
                          จัดการสต๊อกวัตถุดิบ
                      </NavLink>
                    </NavItem>

                      
                        <UncontrolledDropdown nav inNavbar>
                          <DropdownToggle nav>
                            จัดการระบบ <FontAwesomeIcon icon={faAngleDown} />{" "}
                          </DropdownToggle>
                          <DropdownMenu right>
                            {/* <UncontrolledDropdown nav inNavbar> */}

                                <DropdownToggle nav>
                                    จัดการสินค้า 
                                        <DropdownItem tag={Link} to={"/management/article_product"}>
                                          <NavLink>เพิ่ม/แก้ไข สินค้า </NavLink> 
                                        </DropdownItem>
                                        <DropdownItem tag={Link} to={"/management/category_product"}>
                                          <NavLink> จัดการหมวดหมู่สินค้า </NavLink>
                                        </DropdownItem>
                                </DropdownToggle>

                                <DropdownToggle nav>
                                   จัดการวัตถุดิบ 
                                     <DropdownItem tag={Link} to={"/management/article"}>
                                        <NavLink> เพิ่ม/แก้ไข วัตถุดิบ </NavLink>
                                      </DropdownItem>
                                      <DropdownItem tag={Link} to={"/management/category"}>
                                        <NavLink> จัดการหมวดหมู่วัตถุดิบ </NavLink>
                                      </DropdownItem>
                              </DropdownToggle>

                              <DropdownToggle nav>
                                จัดการประวัติ 
                                <DropdownItem tag={Link} to={"/history/stock_product"}>
                                  <NavLink> ประวัติสต็อกสินค้า </NavLink>
                                </DropdownItem>
                                <DropdownItem tag={Link} to={"/history/stock"}>
                                  <NavLink> ประวัติสต็อกวัตถุดิบ </NavLink>
                                </DropdownItem>
                              </DropdownToggle>

                              {superuserAccess == true ? (
                                <>
                                  <NavItem>
                                    <NavLink tag={Link} to={"/management/user"}>
                                      จัดการผู้ใช้งาน
                                    </NavLink>
                                  </NavItem>
                                </>
                              ) : (
                                ""
                              )}

                            
                            {/***********  ADMIN and SUPER USER can ACCESS *****************/}
                            
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      

                      </>
                    ) : (
                      ""
                    )}
        
                  <NavItem>
                    <NavLink tag={Link}>
                      <img
                        className="rounded-circle mr-2"
                        style={{ height: 25 }}
                        src={user.user_image}
                        alt=""
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink tag={Link}>
                      ชื่อผู้ใช้ : {user.user_name} {"  "}
                      {user.role == "MERCHANT" ? (
                        <>
                          <Badge color="success">Merchant</Badge>
                        </>
                      ) : (
                        ""
                      )}
                      {user.role == "ADMIN" ? (
                        <>
                          <Badge color="info">Admin</Badge>
                        </>
                      ) : (
                        ""
                      )}
                      {user.role == "SUPERUSER" ? (
                        <>
                          <Badge color="danger">Super User</Badge>
                        </>
                      ) : (
                        ""
                      )}
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      tag={Link}
                      onClick={() => {
                        authen.auth().signOut();
                        history.push("/");
                      }}
                    >
                      ออกจากระบบ
                    </NavLink>
                  </NavItem>
                </>
              ) : (
                <>
                  <NavItem>
                    <NavLink tag={Link} to={"/login"}>
                      เข้าสู่ระบบ
                    </NavLink>
                  </NavItem>
                </>
              )}

              {/*  */}
            </Nav>
          </Collapse>
        </Navbar>
      )}
    </>
  );
};

export default Topbar;

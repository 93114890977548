import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Badge,
  Card,
  InputGroup,
  Button,
  Label,
  Input,
  CardBody,
} from "reactstrap";
import { useHistory } from "react-router-dom";
import * as role from "../../../util/UserRole";
import * as TransactionStatus from "../../../util/TransactionStatus";
import { useForm } from "react-hook-form";
import { submit } from "redux-form";
import * as actions from "../../../actions";

const Tables = ({ order, index, onChangeStatus }) => {
  const date = new Date(order.orderDate);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { register, handleSubmit, err } = useForm();
  // Role Authorization
  const allowAccess =
    user.role == role.MERCHANT_ROLE ||
    user.role == role.ADMIN_ROLE ||
    user.role == role.SUPER_USER_ROLE
      ? true
      : false;

  // Check Status for Correct button
  const statusBilledCorrect = (status) =>
    status == TransactionStatus.PROC_BILL_CORRECT ||
    status == TransactionStatus.PROC_TRANS ||
    status == TransactionStatus.PROC_COMPLETE;

  const statusNowTransit = (status) =>
    status == TransactionStatus.PROC_TRANS ||
    status == TransactionStatus.PROC_COMPLETE;

  const statusTransactionSuccess = (status) =>
    status == TransactionStatus.PROC_COMPLETE;

  const handlingTrackingNumber = (data) => {
    let confirm = window.confirm("ยืนยันการแก้ไข Tracking Number");
    if (confirm) {
      dispatch(actions.trackingNumberAdding(order, data.TrackingNumber));
      console.log("Success");
    }
  };

  /********** COMPONENT ********************** */
  // Handle Button
  const buttonCorrect = () => (
    <button
      disabled={statusBilledCorrect(order.status)}
      className={
        statusBilledCorrect(order.status)
          ? "btn btn-sm btn-success ml-2 "
          : "btn btn-sm btn-secondary ml-2"
      }
      onClick={() => {
        let confirm = window.confirm(
          "ยืนยันการชำระเงินถูกต้อง และ ตัดสต๊อกสินค้า"
        );
        if (confirm) {
          onChangeStatus(order, TransactionStatus.PROC_BILL_CORRECT);
        }
      }}
    >
      ถูกต้อง
    </button>
  );

  const buttonTransit = () => (
    <button
      disabled={statusNowTransit(order.status)}
      className={
        statusNowTransit(order.status)
          ? "btn btn-sm btn-success ml-2 "
          : "btn btn-sm btn-secondary ml-2"
      }
      onClick={() => {
        let confirm = window.confirm("ยืนยันว่าได้ทำการจัดส่งแล้ว");
        if (confirm) {
          onChangeStatus(order, TransactionStatus.PROC_TRANS);
        }
      }}
    >
      จัดส่ง
    </button>
  );

  const buttonSuccess = () => (
    <button
      disabled={statusTransactionSuccess(order.status)}
      className={
        statusTransactionSuccess(order.status)
          ? "btn btn-sm btn-success ml-2 mr-2 "
          : "btn btn-sm btn-secondary ml-2 mr-2"
      }
      onClick={() => {
        let confirm = window.confirm(
          "ลูกค้าได้รับสินค้าเรียบร้อย รายการสั่งซื้อสินค้าจะถูกจัดเก็บ"
        );
        if (confirm) {
          onChangeStatus(order, TransactionStatus.PROC_COMPLETE);
        }
      }}
    >
      สำเร็จ
    </button>
  );

  // Add Tracking Number Form
  const trackingNumberForm = () => {
    if (order.status == TransactionStatus.PROC_TRANS) {
      return (
        <form>
          <br />
          <InputGroup className="justify-content-center">
            <Input
              type="text"
              name="TrackingNumber"
              style={{ fontSize: "14px" }}
              defaultValue={order.TrackingNumber ? order.TrackingNumber : ""}
              placeholder="หมายเลขติดตามการขนส่ง"
              innerRef={register}
            />{" "}
            <Button
              style={{ fontSize: "14px" }}
              onClick={handleSubmit(handlingTrackingNumber)}
              color="warning"
            >
              บันทึก{" "}
            </Button>
          </InputGroup>{" "}
        </form>
      );
    }
  };

  /******USER INTERFACE ********** */
  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{order._id}</td>
      <td>{date.toLocaleDateString()} </td>
      <td>
        {order.orders &&
          order.orders.map((record) => {
            return (
              <div key={record.product.product_id}>
                <div>{record.product.product_name}</div>
                <div>จำนวน {record.quantity}</div>
              </div>
            );
          })}
      </td>
      <td>{order.totalPrice}</td>
      <td>
        <div> {order.user_name}</div>
        <div> {order.user_address}</div>{" "}
      </td>
      <td>{order.TrackingNumber ? order.TrackingNumber : "-"}</td>
      <td>{order.status}</td>
      <td>
        {" "}
        {allowAccess == true ? (
          <>
            <div className="d-flex justify-content-center">
              {buttonCorrect()}
              {buttonTransit()}
              {buttonSuccess()}
            </div>
            {trackingNumberForm()}
          </>
        ) : (
          <></>
        )}
      </td>
    </tr>
  );
};

export default Tables;
